import React from "react";
import { useState, useRef } from "react";
import { Collapse } from "@mui/material";
import {
  IconButton,
  Input,
  Stack,
  Button,
  Tooltip,
  Box,
  Divider,
} from "@mui/joy";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MyModal from "../MyModal";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import getYouTubeID from "get-youtube-id";
import { TransitionGroup } from "react-transition-group";

function AddYouTube(props) {
  const [showAddUrl, setShowAddUrl] = useState(false);
  const [youTubeLinks, setYouTubeLinks] = useState(props.youTubeLinks || []);

  // if youTubeLinks length is bigger that 8, then we will not allow to add more links
  const isAddNewLinkDisabled = youTubeLinks.length >= 6;

  const showModalHandler = () => {
    setShowAddUrl(true);
  };

  const hideModalHandler = () => {
    setShowAddUrl(false);
  };

  const onAddNewLinkField = () => {
    setYouTubeLinks((prev) => [...prev, ""]);
  };

  const onRemoveLinkField = (idx) => {
    setYouTubeLinks((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1)]);
  };

  const onAddYouTubeLinkClicked = () => {
    const youTubeIDs = youTubeLinks.map((eachLink) => getYouTubeID(eachLink));
    if (props.onAddYouTubeLink) {
      props.onAddYouTubeLink(youTubeIDs);
    }
    hideModalHandler();
  };

  return (
    <>
      <MyModal open={showAddUrl} onClose={hideModalHandler}>
        <Box sx={{ minWidth: 400 }}>
          <TransitionGroup>
            {youTubeLinks.map((eachLink, idx) => (
              <Collapse key={idx}>
                <Stack sx={{ mb: 1 }} direction={"row"} spacing={2}>
                  <Input
                    label={`YouTube Link # ${idx + 1}`}
                    fullWidth
                    value={eachLink}
                    onChange={(e) => {
                      const newYouTubeLinks = [...youTubeLinks];
                      newYouTubeLinks[idx] = e.target.value;
                      setYouTubeLinks(newYouTubeLinks);
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => onRemoveLinkField(idx)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Stack>
              </Collapse>
            ))}
          </TransitionGroup>
          <Button
            color="primary"
            variant="solid"
            size="sm"
            onClick={onAddNewLinkField}
            disabled={isAddNewLinkDisabled}
          >
            Add New Link
          </Button>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
            sx={{ mt: 1 }}
          >
            <Button
              variant="solid"
              aria-label="Create Workout"
              onClick={onAddYouTubeLinkClicked}
            >
              Add/Update Videos
            </Button>
            <Button
              color="danger"
              variant="outlined"
              aria-label="Create Workout"
              onClick={hideModalHandler}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </MyModal>
      {!props.readOnly && (
        <Tooltip title="Add YouTube Video" placement="top" arrow>
          <IconButton aria-label="Add YouTube Vide" onClick={showModalHandler}>
            <YouTubeIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default AddYouTube;
