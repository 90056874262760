/* ------ This is the Global enum definition.---------
 * Note: DO NOT change enum definition unless it won't break anything
 */

/* ------ [Programming TYPE Type] -------- */
// !! in backend we use same string to check programming type
export const TYPE = {
  fix: "fix", // fix set of workouts
  timeline: "timeline", // timeline set of workout
  reoccurring: "reoccurring", // keeps being updated, and is calender depended
};

export const INIT = {
  type: TYPE.fix,
};

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

/* ------ [Programming STATUS Type] -------- */
// !! in backend we use same string to check programming status
export const STATUS = {
  active: "active",
  inactive: "inactive",
  archived: "archived",
};
