import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Button,
  Stack,
} from "@mui/joy";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import { motion, useAnimation } from "framer-motion";
import { Timestamp } from "firebase/firestore";

/* own module */
import { cancelSubscription } from "../../store/firebaseActions";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";

/* ------ stylig */
const useStyles = makeStyles((theme) => ({
  card: {
    width: 50,
    height: 60,
    borderRadius: 5,
    transition: "0.3s",
    background: theme.palette.background.default,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      boxShadow: "0 20px 80px -12.125px rgba(0,0,0,0.3)",
    },
    overflow: "hidden",
  },
}));

/* =================== Subscription item image ============== */
const artCoverVarients = {
  appear: { opacity: 1 },
};

const ItemImage = (props) => {
  const classes = useStyles();
  const artCoverAnimate = useAnimation();

  const imageLoaded = () => {
    artCoverAnimate.start("appear");
  };

  return (
    <>
      <motion.div key="image" whileHover={{ scale: 1.02 }}>
        <motion.img
          className={classes.card}
          initial={{ opacity: 0 }}
          src={props.src}
          variants={artCoverVarients}
          animate={artCoverAnimate}
          transition={{ duration: 0.5 }}
          onLoad={imageLoaded}
          width="100%"
        />
      </motion.div>
    </>
  );
};

const ActiveSubscriptionItem = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const subscription = props.subscription;
  const subscriptionID = subscription.id;
  const subscriptionItems = subscription.items || [];
  const cancelAtPeriodEnd = subscription.cancel_at_period_end || false;

  // connected stripe ID where the subscription is belong to
  const stripeAccountID =
    subscription.items[0].price.product.metadata.stripeAccountID || null;

  /* on Cancel Subscription */
  const onCancelSubscription = () => {
    setLoading(true);
    cancelSubscription(subscriptionID, stripeAccountID)
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Subscription canceled",
          message: `Your subscription has been canceled`,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "Subscription cancelation failed",
          message: `Your subscription cancelation failed`,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* ----- status dependent  */
  let chipColor = "info";

  switch (subscription.status || "") {
    case "active":
      chipColor = "success";
      break;
    case "canceled":
      chipColor = "neutral";
      break;
    case "incomplete":
      chipColor = "warning";
      break;
    case "incomplete_expired":
      chipColor = "error";
      break;
    case "past_due":
      chipColor = "error";
      break;
    case "trialing":
      chipColor = "info";
      break;
    case "unpaid":
      chipColor = "error";
      break;
    default:
      chipColor = "info";
      break;
  }

  const disabledAccordion = subscription.status !== "active";
  /* ------- [Returns] --------- */
  return (
    <Accordion disabled={disabledAccordion}>
      <AccordionSummary>
        <Box>
          {subscriptionItems.length > 0 &&
            subscriptionItems.map((item, idx) => (
              <Stack direction="row" spacing={2} key={idx}>
                {!smallScreen && (
                  <ItemImage src={item.price.product.images[0]} />
                )}
                <Stack>
                  <Typography level="body-sm">
                    {item.price.product.name}
                  </Typography>
                  <Typography level="body-xs">
                    {item.price.product.id}
                  </Typography>
                  {cancelAtPeriodEnd && subscription.cancel_at && (
                    <Typography level="body-xs">
                      {`Cancel at: ${new Timestamp(
                        subscription.cancel_at.seconds,
                        subscription.cancel_at.nanoseconds
                      )
                        .toDate()
                        .toLocaleString()}`}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            ))}
        </Box>
        <Box sx={{ flex: "1 1 auto" }} />
        <Chip color={chipColor} variant="solid">
          {subscription.status}
        </Chip>
        {!cancelAtPeriodEnd && subscription.status === "active" && (
          <>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              variant="outlined"
              color="danger"
              size="sm"
              onClick={onCancelSubscription}
              loading={loading}
            >
              Cancel plan
            </Button>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Typography level="body-sm">Current Period</Typography>
          <Typography level="body-sm">
            {`Start: ${new Timestamp(
              subscription.current_period_start.seconds,
              subscription.current_period_start.nanoseconds
            ).toDate()}`}
          </Typography>
          <Typography level="body-sm">
            {`Ends:  ${new Timestamp(
              subscription.current_period_end.seconds,
              subscription.current_period_end.nanoseconds
            ).toDate()}`}
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ActiveSubscriptionItem;
