import { addDoc } from "firebase/firestore";
import { movementCollection } from "./firebaseApp";
import { createSlice } from "@reduxjs/toolkit";
import { loadMovementLibrary } from "./firebaseActions";

const movLibSlice = createSlice({
  name: "movLib",
  initialState: { showModal: false, movementsLibrary: [] },
  reducers: {
    showModal(state, actions) {
      state.showModal = actions.payload;
    },
    updateMovementLibrary(state, actions) {
      state.movementsLibrary = actions.payload;
    },
  },
});

export const movLibActions = movLibSlice.actions;
export default movLibSlice;

/*
 * load movement library "action creator"
 */
export const loadMovLib = () => {
  return async (dispatch) => {
    const movLib = await loadMovementLibrary();
    dispatch(movLibActions.updateMovementLibrary(movLib));
  };
};

/*
 * Add movement to library
 */

export const addNewMovement = async (newMov) => {
  const docRef = await addDoc(movementCollection, newMov);
  return docRef;
};
