import React from "react";
import { makeStyles } from "@mui/styles";

/* stylig */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    height: "auto",
    width: "auto",
  },
}));

const PageSection = (props) => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};

export default PageSection;
