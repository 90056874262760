import { createSlice } from "@reduxjs/toolkit";
import * as PRICING from "./../constants/Pricing";
import { loadStripe } from "@stripe/stripe-js";
import { createStripeCheckoutSession } from "./StripeActions";
import { notifyActions, NOTIFICATION_TYPE } from "./notification-slice";
import { is } from "date-fns/locale";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    processingCheckout: false,
    showCheckout: false,
    cartItems: [],
    totalPrice: Number((0.0).toFixed(2)),
  },
  reducers: {
    showCheckout(state, actions) {
      state.showCheckout = actions.payload;
    },
    processingCheckout(state, actions) {
      state.processingCheckout = actions.payload;
    },
    addToCart(state, actions) {
      /* Check if we have it already */
      const idx = state.cartItems.findIndex(
        (item) => item.programUID === actions.payload.programUID
      );

      if (idx === -1) {
        // -1 is return of findIndex indicating that no item found
        state.cartItems.push(actions.payload);
        if (actions.payload.pricing.type !== PRICING.TYPE.free) {
          state.totalPrice = Number(
            (state.totalPrice + actions.payload.pricing.amount).toFixed(2)
          );
        }
      } else {
        //TODO: dispatch warning message
      }
    },
    removeItem(state, actions) {
      /* deduct total amount */
      const idx = state.cartItems.findIndex(
        (item) => item.programUID === actions.payload
      );
      state.totalPrice = Number(
        (state.totalPrice - state.cartItems[idx].pricing.amount).toFixed(2)
      );

      /* remove item */
      const newCartItems = state.cartItems.filter(
        (item) => item.programUID !== actions.payload
      );
      state.cartItems = newCartItems;
    },
    resetCart(state) {
      state.processingCheckout = false;
      state.showCheckout = false;
      state.cartItems = [];
      state.totalPrice = 0.0;
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice;

export const checkout = (cartItems) => {
  return async (dispatch) => {
    dispatch(cartActions.processingCheckout(true));

    /* Creating Product on Stripe */
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

    /* creating checkout list items */
    let subscriptionIncluded = false;
    const listPriceIDs = [];
    const listProductIDs = [];
    let stripeAccountID = null;
    let isLemonadeProduct = false;

    try {
      cartItems.forEach((item) => {
        /* check for lemonade product */
        if (item.isLemonadeProduct) {
          isLemonadeProduct = true;
        }
        /* check for stripeAccountID */
        if (item.stripeAccountID) {
          if (!stripeAccountID) {
            stripeAccountID = item.stripeAccountID;
          } else if (stripeAccountID !== item.stripeAccountID) {
            throw new Error(
              "You can't buy products from different coaches at the same time."
            );
          }

          if (isLemonadeProduct) {
            throw new Error(
              "You can't buy lemonade products at the same time with other products."
            );
          }
        }

        listPriceIDs.push({ price: item.priceID, quantity: 1 });
        listProductIDs.push(item.programUID);
        if (item.pricing.type === PRICING.TYPE.subscription) {
          subscriptionIncluded = true;
        }
      });
    } catch (err) {
      const notification = {
        severity: NOTIFICATION_TYPE.error,
        title: "Checkout ERROR",
        message: err.message,
      };
      dispatch(notifyActions.sendNotification(notification));
      dispatch(cartActions.processingCheckout(false));
      return;
    }

    // call create checkout session in backend
    try {
      const checkoutSession = await createStripeCheckoutSession({
        mode: subscriptionIncluded ? "subscription" : "payment",
        line_items: listPriceIDs,
        success_url: `${window.location.origin}/payment-status?status=success`,
        cancel_url: `${window.location.origin}/payment-status?status=fail`,
        stripeAccountID: stripeAccountID,
        isLemonadeProduct: isLemonadeProduct,
      });

      if (checkoutSession.data.url) {
        window.location.replace(checkoutSession.data.url);
      }
    } catch (err) {
      const notification = {
        severity: NOTIFICATION_TYPE.error,
        title: "Checkout ERROR",
        message: err.message,
      };
      dispatch(notifyActions.sendNotification(notification));
      dispatch(cartActions.processingCheckout(false));
      return;
    }
  };
};
