import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Divider, Stack } from "@mui/joy";
import { motion } from "framer-motion";
import { makeStyles } from "@mui/styles";

/*-----  [Authentication imports] ------ */
import AuthContext from "../../store/auth-context";
import WorkoutScore from "./WorkoutScore";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    height: "auto",
  },
}));

const ViewScoreItem = (props) => {
  const classes = useStyles();
  const authCtx = useContext(AuthContext);
  const [AvatarPicURL, setAvatarPicURL] = useState(props.avatar);

  useEffect(() => {
    const loadPic = async () => {
      if (props.score.athleteUID) {
        authCtx.getProfilePicURL(props.score.athleteUID).then((url) => {
          setAvatarPicURL(url);
        });
      }
    };

    return loadPic();
  }, [authCtx, props.score.athleteUID]);

  return (
    <motion.div
      className={classes.card}
      variants={{
        hidden: (i) => ({
          opacity: 0,
          y: -50 * i,
        }),
        visible: (i) => ({
          opacity: 1,
          y: 0,
          transition: {
            delay: i * 0.05,
          },
        }),
      }}
      initial="hidden"
      animate="visible"
      key={props.idx}
      custom={props.idx}
    >
      <Stack direction="row" spacing={1} alignItems="center" sx={{ mr: 1 }}>
        <Avatar
          sx={{ height: 40, width: 40 }}
          src={AvatarPicURL}
          aria-label="Coach Pic"
        />
        <Button size="small" variant="outlined">
          {props.score.athleteName}
        </Button>
      </Stack>
      <WorkoutScore
        viewOnly
        score={props.score}
        scoreType={props.score.scoreType}
      />
      <Divider sx={{ mt: 2 }} />
    </motion.div>
  );
};

export default ViewScoreItem;
