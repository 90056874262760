import React, { Fragment, useRef } from "react";
import { Chip, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

const WorkingWeightChip = (props) => {
  const userData = useSelector((state) => state.user.userInfoData);
  const workingWeight = useRef(0);
  let visible = false;

  /* Calculating based on 1RM */
  userData.Records_1RM.forEach((record) => {
    if (props.movID && record.id === props.movID) {
      visible = true;
      workingWeight.current = (record._1RM * props.percentage) / 100;
    }
  });

  return (
    <Fragment>
      {visible && (
        <Tooltip title="Calculated weight based on your reported 1RM" arrow>
          <Chip
            variant="outlined"
            label={`${workingWeight.current} kg`}
            icon={<FitnessCenterIcon />}
          />
        </Tooltip>
      )}
    </Fragment>
  );
};

export default WorkingWeightChip;
