/*
 * Lemonade Training Oy, copy rights, all right reserved.
 */
import React, { useState } from "react";
import { Button, Input, Stack } from "@mui/joy";
import { useDispatch } from "react-redux";
import { sendInvitationByEmail } from "../store/firebaseActions";
import { notifyActions, NOTIFICATION_TYPE } from "../store/notification-slice";
import WidgetCard from "../components/Cards/WidgetCard";
import {
  InviteObj,
  productInvitationDetails,
  INVITATION_TYPE,
} from "../Common/InviteObj";
import * as PRODUCT from "../constants/ProductConst";

const SendingInvitationWidget = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  /* ----- Send invitation handler --------- */
  const onSentInvitationHandler = (event) => {
    event.preventDefault();
    setLoading(true);

    /* make a invitation obj */
    const invitation = new InviteObj(props.invitationType);
    if (props.title) invitation.title = props.title;

    switch (props.invitationType) {
      case INVITATION_TYPE.toProgrammingProduct:
        invitation.product.type = props.productType;
        invitation.product.UID = props.productUID;
        invitation.product.ownerUID = props.productOwnerUID;
        break;
      case INVITATION_TYPE.toCoachingSubProduct:
        invitation.product.type = props.productType;
        invitation.product.UID = props.productUID;
        invitation.product.ownerUID = props.productOwnerUID;
        break;
      default:
        break;
    }

    /* send the invitation */
    sendInvitationByEmail(email, invitation)
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Invitation sent",
          message: `Invitation sent to ${email}`,
        };
        dispatch(notifyActions.sendNotification(notification));
        setEmail("");
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "OH OH!",
          message: error.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLoading(false);
        if (props.onClose) props.onClose();
      });
  };

  return (
    <WidgetCard title={props.title || "Send Invites"}>
      <Stack sx={{ mt: 1, mb: 1 }} spacing={2}>
        <Input
          fullWidth
          placeholder="Email"
          id="email"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <Button
          variant="solid"
          color="primary"
          fullWidth
          type="submit"
          disabled={email.length === 0}
          onClick={onSentInvitationHandler}
          loading={loading}
        >
          Send Invites
        </Button>
      </Stack>
    </WidgetCard>
  );
};

export default SendingInvitationWidget;
