/* ------ This is the Global enum definition.---------
 * Note: Change cautiously
 */

export const MEASURE = {
  None: "",
  Reps: "Rep",
  setRep: "Set",
  Meter: "Meter",
  Calorie: "Cal",
  Kilogram: "Kg",
};

export const TYPE = {
  None: "",
  Squats: "Squats",
  Gymnastics: "Gymnastics",
  Weightlifting: "Weightlifting",
  DB_KB: "DB & KB",
  Conditioning: "Conditioning",
};

/*
 * Movements records that will be shown in selected view of 1RM widget.
 */
export const _1RM_SelectedMovements = [
  "Back squat",
  "Front squat",
  "Overhead squat",
  "Squat snatch",
  "Power snatch",
  "Squat clean",
  "Power clean",
  "Deadlift",
  "Push jerk",
  "Split jerk",
  "Push press",
  "Bench press",
  "Shoulder press",
  "Bench Press",
  "Clean & Jerk",
  "Good morning",
  "Romanian deadlift",
  "Clean Pulls",
  "Snatch Pulls",
];

export const LIBRARY = [
  { name: "Air squat", type: TYPE.Squats, measureType: MEASURE.Kilogram },
  { name: "Jumping Squat", type: TYPE.Squats, measureType: MEASURE.Kilogram },
  { name: "Pistol squat", type: TYPE.Squats, measureType: MEASURE.Kilogram },
  { name: "Front squat", type: TYPE.Squats, measureType: MEASURE.Kilogram },
  { name: "Goblet squat", type: TYPE.Squats, measureType: MEASURE.Kilogram },
  { name: "Back squat", type: TYPE.Squats, measureType: MEASURE.Kilogram },
  { name: "Overhead squat", type: TYPE.Squats, measureType: MEASURE.Kilogram },

  { name: "Push up", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "pull up", type: TYPE.Gymnastics, measureType: MEASURE.none },
  {
    name: "weighted chin up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  {
    name: "weighted pull up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  { name: "rig pull up", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "strict c2b", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "kipping pull up", type: TYPE.Gymnastics, measureType: MEASURE.none },
  {
    name: "kippimg c2b chin up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  { name: "T2B", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "strict t2b", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "kipping t2b", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "burpee", type: TYPE.Gymnastics, measureType: MEASURE.none },
  {
    name: "handtstand push up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  { name: "kipping hspu", type: TYPE.Gymnastics, measureType: MEASURE.none },
  {
    name: "parallel bar dips",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  { name: "ring dip", type: TYPE.Gymnastics, measureType: MEASURE.none },
  {
    name: "strict muscle up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  {
    name: "kipping ring muscle up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  {
    name: "eccentric ring muscle up,",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  {
    name: "strict bar muscle up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  {
    name: "kipping bar muscle up",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  { name: "handstand hold", type: TYPE.Gymnastics, measureType: MEASURE.none },
  {
    name: "handstand walking",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  { name: "L-sit", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "v sit-up", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "sit up", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "Hollow rock", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "hollow hold", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "ghd sit up", type: TYPE.Gymnastics, measureType: MEASURE.none },
  {
    name: "ghd back extension",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  {
    name: "ghd hip extension",
    type: TYPE.Gymnastics,
    measureType: MEASURE.none,
  },
  { name: "Box jump", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "burpee box jump", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "Knees to elbow", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "Leg raise", type: TYPE.Gymnastics, measureType: MEASURE.none },
  { name: "Wall walk", type: TYPE.Gymnastics, measureType: MEASURE.none },

  {
    name: "Power snatch",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Power clean",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Muscle snatch",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Muscle clean",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Squat snatch",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Squat clean",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Clean & Jerk",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Split jerk",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Push jerk",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Push press",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Shoulder press",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Snatch pulls",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Clean pulls",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  { name: "Deadlift", type: TYPE.Weightlifting, measureType: MEASURE.Kilogram },
  {
    name: "Romanian deadlift",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "Good morning",
    type: TYPE.Weightlifting,
    measureType: MEASURE.Kilogram,
  },

  { name: "Single unders", type: TYPE.Conditioning, measureType: MEASURE.none },
  { name: "Double unders", type: TYPE.Conditioning, measureType: MEASURE.none },
  { name: "Triple unders", type: TYPE.Conditioning, measureType: MEASURE.none },
  { name: "Rowing", type: TYPE.Conditioning, measureType: MEASURE.none },
  { name: "Assualt bike", type: TYPE.Conditioning, measureType: MEASURE.none },
  { name: "Bike", type: TYPE.Conditioning, measureType: MEASURE.none },
  { name: "Running", type: TYPE.Conditioning, measureType: MEASURE.none },
  { name: "Ski erg", type: TYPE.Conditioning, measureType: MEASURE.none },
  {
    name: "Assault runner",
    type: TYPE.Conditioning,
    measureType: MEASURE.none,
  },
  { name: "Swimming", type: TYPE.Conditioning, measureType: MEASURE.none },

  { name: "DB snatch", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "KB snatch", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "DB & KB clean", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "KB swing Russian", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  {
    name: "KB American swing",
    type: TYPE.DB_KB,
    measureType: MEASURE.Kilogram,
  },
  { name: "Turkish get up", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "KB Strict press", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "KB push press", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "KB push jerk", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "DB strict press", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "DB push press", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "DB push jerk", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "DB box step over", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "Devils press", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "KB walking lunge", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  { name: "DB walking lunge", type: TYPE.DB_KB, measureType: MEASURE.Kilogram },
  {
    name: "KB overhead lunge",
    type: TYPE.DB_KB,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "DB overhead lunge",
    type: TYPE.DB_KB,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "KB overhead squat",
    type: TYPE.DB_KB,
    measureType: MEASURE.Kilogram,
  },
  {
    name: "DB overhead squat",
    type: TYPE.DB_KB,
    measureType: MEASURE.Kilogram,
  },
];
