import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
/* Material UI */
import { IconButton, Badge } from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const CartBadgeButton = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const onCartButtonClick = () => {
    dispatch(cartActions.showCheckout(true));
  };

  return (
    <Fragment>
      <IconButton
        aria-label="cart"
        sx={{ padding: 0, width: 50, height: 50 }}
        onClick={onCartButtonClick}
        color="primary"
      >
        <Badge
          badgeContent={cart.cartItems.length}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ShoppingBagIcon fontSize="large" />
        </Badge>
      </IconButton>
    </Fragment>
  );
};

export default CartBadgeButton;
