import React, { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/joy";
import ProductCard from "./ProductCard/ProductCard";
import { loadUsersOwnProducts } from "../store/firebaseActions";
import * as PRODUCT from "./../constants/ProductConst";

/* firebase actions */
import { getUserLibrary } from "../store/firebaseActions";

const ProgrammingSearchEngine = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
  const [foundProgramming, setFoundProgramming] = useState([]);
  const [subscribedProgramming, setSubscribedProgramming] = useState([]);

  /* --------- [Load users subscriptions from library] ---------*/
  useEffect(() => {
    const loadProgramming = async () => {
      if (props.searchSubscriptions) {
        getUserLibrary()
          .then((newData) => {
            setSubscribedProgramming(newData.data);
            setIsSubscriptionLoading(false);
          })
          .catch((error) => {
            console.log("[Search subescription, ERROR]->", error);
          });
      }
    };

    // executing use effect
    return loadProgramming();
  }, [props.searchSubscriptions]);

  /* --------- [Search Programming created by current user] ---------*/
  useEffect(() => {
    const loadOwnProgramming = async () => {
      if (props.searchUserOwnProgramming) {
        setIsLoading(true);
        let flags = [];
        if (props.PT) {
          flags = ["isPT"];
        }
        loadUsersOwnProducts(PRODUCT.TYPE.Programming, flags)
          .then((docs) => {
            setFoundProgramming(docs.data);
          })
          .catch((error) => {
            console.log("[ProgrammingSearchEngine, useEffect]->", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };

    // executing use effect
    return loadOwnProgramming();
  }, [props.searchUserOwnProgramming, props.PT]);

  return (
    <>
      <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
        {!isLoading &&
          !foundProgramming.length &&
          !props.searchSubscriptions && (
            <Typography>No Programming has been found!!</Typography>
          )}
        {isLoading && <CircularProgress color="primary" sx={{ m: 5 }} />}
        {!isLoading &&
          foundProgramming.map((eachProgramming) => (
            <Stack key={eachProgramming.id}>
              <ProductCard
                disableOnClick={!props.searchUserOwnProgramming}
                disableAddToCart={props.searchUserOwnProgramming}
                UID={eachProgramming.id}
                data={eachProgramming.data}
              />
            </Stack>
          ))}
      </Stack>
      {props.searchSubscriptions && (
        <Stack spacing={4} direction="row" flexWrap="wrap" useFlexGap>
          {isSubscriptionLoading && (
            <CircularProgress color="primary" sx={{ m: 5 }} />
          )}
          {!isSubscriptionLoading &&
            subscribedProgramming.map((eachProgramming) => (
              <Stack key={eachProgramming.id}>
                <ProductCard
                  disableAddToCart
                  UID={eachProgramming.id}
                  data={eachProgramming.data}
                />
              </Stack>
            ))}
        </Stack>
      )}
    </>
  );
};

export default ProgrammingSearchEngine;
