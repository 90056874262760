import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/joy";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "./../../store/notification-slice";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import ViewScoreItem from "./ViewScoreItem";
import WidgetCard from "../Cards/WidgetCard";
import { loadWorkoutResults } from "../../store/firebaseActions";

const ViewAthletesScoresWidget = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [Loading, setLoading] = useState(true);
  const [scores, setScores] = useState([]);

  useEffect(
    (scores) => {
      const loadScores = async () => {
        await loadWorkoutResults(props.workoutCardUID, user.uid)
          .then((data) => {
            setScores(data.data);
          })
          .catch((err) => {
            const notification = {
              severity: NOTIFICATION_TYPE.error,
              title: "Reading workout score error",
              message: err.message,
            };
            dispatch(notifyActions.sendNotification(notification));
          });
        setLoading(false);
      };

      return loadScores();
    },
    [props.workoutCardUID]
  );

  return (
    <WidgetCard title="Athletes Scores">
      {Loading && <CircularProgress color="primary" sx={{ m: 5 }} />}
      <AnimatePresence>
        {scores.length > 0 &&
          scores.map((eachScore, idx) => (
            <ViewScoreItem key={idx} idx={idx} score={eachScore.data} />
          ))}
      </AnimatePresence>
    </WidgetCard>
  );
};

export default ViewAthletesScoresWidget;
