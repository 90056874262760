import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import React, { useState, useEffect, useRef } from "react";
import {
  firebaseAuth,
  firebaseDB,
  firebaseStorage,
  movementCollection,
  COLLECTIONS,
} from "./firebaseApp";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  setDoc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import * as MOVEMENT from "./../constants/MovementConst.js";

/* .... */
import { useDispatch, useSelector } from "react-redux";
import { userActions, loadUserInfoData, loadUser1RM } from "./user-slice";
import { cartActions } from "./cart-slice";

const AuthContext = React.createContext({
  user: null,
  userType: 0,
  isLoggedIn: false,
  loginWithEmail: (email, password) => {},
  singupWithEmail: (email, password) => {},
  logout: () => {},
  resestPassword: (email) => {},
});

export const AuthContextProvider = (props) => {
  const dispatch = useDispatch();
  const movementsLibrary = useSelector(
    (state) => state.movLib.movementsLibrary
  );
  const [currentUser, setCurrentUser] = useState(null);

  const userIsLoggedIn = !!currentUser;
  const [initLoading, setInitLoading] = useState(true);

  /* ---- [Auth and user managment] -----------*/
  const singupWithEmail = (email, password) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
  };

  const loginWithEmail = async (email, password) => {
    const userCredintial = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );

    return userCredintial;
  };

  const userLogOut = async () => {
    await signOut(firebaseAuth);
    dispatch(cartActions.resetCart());
    dispatch(userActions.logOut());
  };

  const resestPassword = (email) => {
    return sendPasswordResetEmail(firebaseAuth, email);
  };

  const updateCustomClaims = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    user.getIdTokenResult(true).then((idTokenResult) => {
      let claims = {};
      claims.admin = idTokenResult.claims?.admin || false;
      claims.lemonadeAdmin = idTokenResult.claims?.lemonadeAdmin || false;
      claims.box = idTokenResult.claims?.box || false;
      claims.coach = idTokenResult.claims?.coach || false;
      claims.athlete = idTokenResult.claims?.athlete || true;
      claims.role = idTokenResult.claims?.role || "Athlete";
      claims.accessLevel = idTokenResult.claims?.accessLevel || 0;
      dispatch(userActions.setClaims(claims));
    });
  };

  const updateUserProfile = async (fulleName, profilePicURL) => {
    updateProfile(currentUser, {
      displayName: fulleName,
      photoURL: profilePicURL,
    })
      .then(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        updateCustomClaims();
        setCurrentUser(user);
        dispatch(userActions.setUser(user));
      })
      .catch((error) => {
        // TODO: An error occurred
        // ...
        console.log("Error updating user profile ------ ");
        console.log(error);
      });
  };

  /* ---- [Use Records ] --------  */
  const initUsers1RmRcordCollection = async (userUID) => {
    if (movementsLibrary.length > 0) {
      const selectedMovments = [];
      MOVEMENT._1RM_SelectedMovements.forEach((movName) => {
        movementsLibrary.forEach((movInMovLib) => {
          if (movName === movInMovLib.name) {
            selectedMovments.push({ ...movInMovLib, _1RM: 0 });
          }
        });
      });

      let docRef;
      await selectedMovments.forEach(async (mov) => {
        docRef = doc(
          firebaseDB,
          COLLECTIONS.Users,
          userUID,
          COLLECTIONS._1RM,
          mov.id
        );

        await setDoc(docRef, mov).then(() => {});
      });
    }
  };

  const update1rmRecord = (movId, oneRepMax) => {
    const userRef = doc(
      firebaseDB,
      COLLECTIONS.Users,
      currentUser.uid,
      COLLECTIONS._1RM,
      movId
    );

    movementsLibrary.forEach((movInMovLib) => {
      if (movId === movInMovLib.id) {
        setDoc(userRef, { ...movInMovLib, _1RM: oneRepMax })
          .then(() => {})
          .catch((error) => {});
      }
    });
  };
  /* ---- [Programming and workout cards]------- */
  const searchThroughProgrammings = async (text) => {
    const performQuery = query(
      collection(firebaseDB, COLLECTIONS.Programmings),
      where("CoachName", "==", text)
    );

    const data = [];
    const querySnapshot = await getDocs(performQuery);
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, data: doc.data() });
    });
    return data;
  };

  /* ---- Movement Library ----------- */
  const addNewMovement = async (newMovement) => {
    const docRef = await addDoc(movementCollection, newMovement);
    console.log("Movement written with ID: ", docRef.id);
  };

  /* ---- [Storage and file uploads]------- */
  const uploadUserProfilePic = async (file) => {
    const userPicRef = ref(
      firebaseStorage,
      `users/${currentUser.uid}/ProfilePic`
    );

    const snapshot = await uploadBytes(userPicRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;

    //TODO: Add error handeling here
  };

  const getProfilePicURL = async (userUID) => {
    const userPicRef = ref(firebaseStorage, `users/${userUID}/ProfilePic`);
    const downloadURL = await getDownloadURL(userPicRef);
    return downloadURL;
  };

  /* -----------[Use Effects]---------------------- */
  /* Firebase auth observer -------------- */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setCurrentUser(user);
      setInitLoading(false);
      if (user) updateCustomClaims();
      dispatch(userActions.setUser(user));
      console.log("---- useEffect Auth");
    });

    return unsubscribe;
  }, [dispatch]);

  const unsubSnapRef = useRef(() => {});
  const unsub1RM_SnapRef = useRef(() => {});
  /* update user info  */
  useEffect(() => {
    const updateUserInfo = async () => {
      if (currentUser) {
        /* create real time listener to user info database */
        const userRef = doc(firebaseDB, COLLECTIONS.Users, currentUser.uid);
        const _1RMColl = collection(
          firebaseDB,
          COLLECTIONS.Users,
          currentUser.uid,
          COLLECTIONS._1RM
        );
        unsub1RM_SnapRef.current = onSnapshot(_1RMColl, (doc) => {
          console.log("[user 1RM snapshot] ");
          dispatch(loadUser1RM(currentUser.uid));
        });
        unsubSnapRef.current = onSnapshot(userRef, (doc) => {
          console.log("[user data snapshot]");
          dispatch(loadUserInfoData(currentUser.uid));
        });
      } else {
        console.log("[UnSubscribe]: ");
        unsubSnapRef.current();
        unsub1RM_SnapRef.current();
        unsubSnapRef.current = () => {};
        unsub1RM_SnapRef.current = () => {};
      }
    };

    updateUserInfo();
  }, [currentUser, dispatch]);

  /* -------------------------------------- */
  const contexValue = {
    user: currentUser,
    isLoggedIn: userIsLoggedIn,
    loginWithEmail,
    singupWithEmail,
    userLogOut,
    updateCustomClaims,
    resestPassword,
    updateUserProfile,
    uploadUserProfilePic,
    getProfilePicURL,
    initUsers1RmRcordCollection,
    update1rmRecord,
    searchThroughProgrammings,
    addNewMovement,
  };

  return (
    <AuthContext.Provider value={contexValue}>
      {!initLoading && props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
