import React from "react";
import { Stack } from "@mui/joy";
import { useSelector } from "react-redux";

/* Own Modules */
import _DashboardBlankPage from "./_DashboardBlankPage";
import OneRMWidget from "../../Widgets/_1RMWidget";

/* =================== OverView Page ==================== */
const OverViewPage = () => {
  const access = useSelector((state) => state.user.claims);
  const hasAdminRights = access.admin || access.lemonadeAdmin;

  return (
    <_DashboardBlankPage title="Dashboard">
      <Stack
        spacing={3}
        direction={"row"}
        sx={{
          display: "flex",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <OneRMWidget />
      </Stack>
    </_DashboardBlankPage>
  );
};

export default OverViewPage;
