/* ------ This is the Global enum definition.---------
 * Note: DO NOT change enum definition unless it won't break anything
 */

/* ------ [Pricing Type] -------- */
// !! in backend we use same string to check price type
export const TYPE = {
  free: "free",
  oneTime: "one time", // one time buy
  subscription: "subscription",
};

export const INIT = {
  type: TYPE.free,
  amount: null, // expecting uint here
};
