import React from "react";
import { CircularProgress, Divider, Typography, Stack, Box } from "@mui/joy";
import MyCard from "./MyCard";

const WidgetCard = (props) => {
  return (
    <MyCard>
      <Box>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={3}>
          <Typography level="title-md">{props.title}</Typography>
          {props.isLoading && <CircularProgress size="sm" />}
        </Stack>
        {props.subTitle && (
          <Typography level="body-sm">{props.subTitle}</Typography>
        )}
      </Box>
      <Divider />
      {props.children}
    </MyCard>
  );
};

export default WidgetCard;
