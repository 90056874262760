import React, { Fragment } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {},
}));

const SiteLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.main}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default SiteLayout;
