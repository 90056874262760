import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Box } from "@mui/joy";
import { cartActions, checkout } from "../../store/cart-slice";
import MyModal from "../MyModal";
import CartItem from "./CartItem";

const CheckoutModal = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const buttonDisabled = cart.cartItems?.length === 0;

  const handleClose = () => {
    dispatch(cartActions.showCheckout(false));
  };

  const handleCheckout = () => {
    dispatch(checkout(cart.cartItems));
  };

  return (
    <MyModal
      open={cart.showCheckout}
      onClose={handleClose}
      backgroundColor={"white"}
    >
      <Box sx={{ mb: 5 }}>
        <Typography level="title-md">Shopping cart</Typography>
        {buttonDisabled && (
          <Typography level="body-sm">Shopping cart empty</Typography>
        )}
      </Box>
      <Box sx={{ mb: 5 }}>
        {cart.cartItems.map((eachItem) => (
          <CartItem
            key={eachItem.programUID}
            imageSrc={eachItem.coverArt}
            title={eachItem.title}
            id={eachItem.programUID}
            pricing={eachItem.pricing}
          />
        ))}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="outlined"
          color="primary"
          loading={cart.processingCheckout}
          onClick={handleCheckout}
          disabled={buttonDisabled}
        >
          {`Checkout ${cart.totalPrice} €`}
        </Button>
      </Box>
    </MyModal>
  );
};

export default CheckoutModal;
