/*
 * ======= NOTIFICATION
 */
import { createSlice } from "@reduxjs/toolkit";

export const NOTIFICATION_TYPE = {
  info: "primary",
  warning: "warning",
  error: "danger",
  success: "success",
};

const INIT = {
  severity: NOTIFICATION_TYPE.info,
  title: "",
  message: "",
  activate: false,
};

const notifySlice = createSlice({
  name: "notification",
  initialState: INIT,
  reducers: {
    sendNotification(state, action) {
      state.severity = action.payload.severity;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.activate = true;
    },
    deactivate(state) {
      state.activate = false;
      state.severity = NOTIFICATION_TYPE.info;
      state.title = "";
      state.message = "";
    },
  },
});

export const notifyActions = notifySlice.actions;
export default notifySlice;
