import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Divider, Stack } from "@mui/joy";

/* Own Modules */
import _DashboardBlankPage from "./_DashboardBlankPage";
import ProgrammingSearchEngine from "../../components/ProgrammingSearchEngine";

/* =================== WorkoutLibrary ==================== */
const WorkoutLibrary = () => {
  const user = useSelector((state) => state.user);

  return (
    <_DashboardBlankPage title="Workout Program Library">
      <Stack
        spacing={4}
        sx={{
          display: "flex",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <ProgrammingSearchEngine searchSubscriptions />
      </Stack>
    </_DashboardBlankPage>
  );
};

export default WorkoutLibrary;
