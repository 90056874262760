import React, { Fragment, useRef, useState } from "react";
import AvatarPopover from "./AvatarPopover";
import { useSelector } from "react-redux";
/* Material UI */
import { alpha } from "@mui/material/styles";
import { MenuItem, IconButton } from "@mui/material";
import { Divider, Button, Box, Typography, Avatar } from "@mui/joy";
import HomeIcon from "@mui/icons-material/Home";

/* Routing */
import { useNavigate } from "react-router-dom";

/* Account Popover options */
const MENU_OPTIONS = [
  {
    label: "Home",
    icon: HomeIcon,
    linkTo: "/",
  },
  {
    label: "Dashboard",
    icon: "",
    linkTo: "/dashboard",
  },
];

/*
 * Header of the site when the user is Signed in.
 */
const UserAvatarAndPopoverMenu = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const goToPage = (page) => {
    navigate(page);
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 50,
          height: 50,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt="photoURL" src={user.photoURL} />
      </IconButton>
      <AvatarPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography level="title-md" noWrap>
            {user.displayName}
          </Typography>
          <Typography level="body-sm" noWrap>
            {user.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            onClick={() => {
              goToPage(option.linkTo);
            }}
          >
            <Typography level="body-sm">{option.label}</Typography>
          </MenuItem>
        ))}
        <Divider sx={{ my: 1 }} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth variant="outlined" onClick={props.onLogout}>
            Logout
          </Button>
        </Box>
      </AvatarPopover>
    </Fragment>
  );
};

export default UserAvatarAndPopoverMenu;
