import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFirestore,
  collection,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import * as PRODUCT from "./../constants/ProductConst";

//  web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// App check
if (
  process.env.NODE_ENV === "development" ||
  window.location.hostname === "localhost"
) {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
/*
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_FIREBASE_APPCHECK_SITE
  ),
  isTokenAutoRefreshEnabled: true,
});
*/

export const firebaseAuth = getAuth(app);
export const firebaseDB = getFirestore(app);
export const firebaseStorage = getStorage(app);
export const firebaseFunctions = getFunctions(
  app,
  process.env.REACT_APP_FIREBASE_SERVER_LOCATION
);
export default app;

/* Emulator setup */
const emuActivate = false;
if (window.location.hostname === "localhost" && emuActivate) {
  console.log(["Running at EMULATOR local host"]);
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFirestoreEmulator(firebaseDB, "localhost", 8080);
  connectStorageEmulator(firebaseStorage, "localhost", 9199);
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
}

/* Constant */
export const COLLECTIONS = {
  Users: "Users",
  UserLibrary: "UserLibrary",
  Subscriptions: "Subscriptions",
  Invites: "Invites",
  Programmings: "ProgrammingsProducts",
  WorkoutCards: "WorkoutCards",
  WorkoutResults: "WorkoutResults",
  Movements: "Movements",
  Records: "Records",
  _1RM: "Records_1RM",
  CoachingSubscriptions: PRODUCT.TYPE.CoachingSubscription,
};

/* References to collections */
export const movementCollection = collection(firebaseDB, COLLECTIONS.Movements);
export const usersCollection = collection(firebaseDB, COLLECTIONS.Users);
export const workoutCollection = collection(
  firebaseDB,
  COLLECTIONS.WorkoutCards
);
