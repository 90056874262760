import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

/* Authentication imports */
import AuthContext from "../store/auth-context";

/* Firebase */
import { collection, onSnapshot } from "firebase/firestore";
import { firebaseDB, COLLECTIONS } from "./../store/firebaseApp";

/* Joy UI*/
import { makeStyles } from "@mui/styles";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import GlobalStyles from "@mui/joy/GlobalStyles";
import { Chip, Avatar, Box } from "@mui/joy";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import CollectionsBookmarkRoundedIcon from "@mui/icons-material/CollectionsBookmarkRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";

/* assets */
import LemonadeTrainingText from "../assets/LemonadeTraining_text-black.PNG";

/* Routing */
import { useNavigate, useLocation, Outlet } from "react-router-dom";

/* own module */
import { INVITATION_STATUS } from "../Common/InviteObj";
import _DashboardHeader from "../pages/_dashboard/_DashboardHeader";
import CurrentPlanCard from "../components/Cards/CurrentPlanCard";

const useStyles = makeStyles((theme) => ({
  Logo: {
    width: "100%",
    height: "auto",
  },
}));

/* ============ [UTILS] ============  */
function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean,
  children: React.ReactNode,
  renderToggle: (params: {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  }) => React.ReactNode,
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export const openSidebar = () => {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
  }
};

export const closeSidebar = () => {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
  }
};

export const toggleSidebar = () => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
};

/* ============ [SideBar] ============  */
const Sidebar = () => {
  const classes = useStyles();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathFirstSegment = pathname.split("/")[1];
  const access = useSelector((state) => state.user.claims);
  const user = useSelector((state) => state.user.user);
  const hasCoachRights = access.coach || access.admin || access.lemonadeAdmin;
  const hasAdminRights = access.admin || access.lemonadeAdmin;

  /* button and link Handlers */
  const goToPage = (pageName) => {
    navigate(pageName);
  };

  /* sign out */
  const signOutTheUser = async () => {
    await authCtx.userLogOut();
    goToPage("/");
  };

  /* shopping badge number  */
  const cartItems = useSelector((state) => state.cart.cartItems);

  /* subscribing to user Invites in database */
  const [Invites, setInvites] = useState([]);
  useEffect(() => {
    const InvitesRef = collection(
      firebaseDB,
      COLLECTIONS.Users,
      user.uid,
      COLLECTIONS.Invites
    );

    const unsubscribe = onSnapshot(InvitesRef, (querySnapshot) => {
      const pendingInvites = [];
      querySnapshot.docs.forEach((doc) => {
        const invite = { id: doc.id, ...doc.data() };
        if (invite.status === INVITATION_STATUS.Pending) {
          pendingInvites.push(invite);
        }
      });
      setInvites(pendingInvites);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  /* ------- UI Returns ----------- */
  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <_DashboardHeader />
      <Sheet
        className="Sidebar"
        sx={{
          position: {
            xs: "fixed",
            md: "sticky",
          },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
            md: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 10000,
          height: "100dvh",
          width: "var(--Sidebar-width)",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider",
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Sidebar-width": "220px",
              [theme.breakpoints.up("lg")]: {
                "--Sidebar-width": "240px",
              },
            },
          })}
        />
        <Box
          className="Sidebar-overlay"
          sx={{
            position: "fixed",
            zIndex: 9998,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            opacity: "var(--SideNavigation-slideIn)",
            backgroundColor: "var(--joy-palette-background-backdrop)",
            transition: "opacity 0.4s",
            transform: {
              xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
              lg: "translateX(-100%)",
            },
          }}
          onClick={() => closeSidebar()}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <img
            src={LemonadeTrainingText}
            alt="Lemonade Training Oy"
            className={classes.Logo}
          />
        </Box>

        <Box
          sx={{
            minHeight: 0,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            [`& .${listItemButtonClasses.root}`]: {
              gap: 1.5,
            },
          }}
        >
          <List
            size="sm"
            sx={{
              gap: 1,
              "--List-nestedInsetStart": "30px",
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
            }}
          >
            <ListItem>
              <ListItemButton role="menuitem" component="a" href="/.">
                <HomeRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Home Page</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton
                onClick={() => {
                  navigate(`/${pathFirstSegment}`);
                }}
              >
                <DashboardRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Dashboard</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton
                onClick={() => navigate(`/${pathFirstSegment}/shopping-cart`)}
              >
                <ShoppingBagRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Shopping Cart</Typography>
                </ListItemContent>
                {cartItems.length > 0 && (
                  <Chip size="sm" color="primary" variant="solid">
                    {cartItems.length}
                  </Chip>
                )}
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton
                onClick={() => navigate(`/${pathFirstSegment}/workout-library`)}
              >
                <CollectionsBookmarkRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Workout Library</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            {hasCoachRights && (
              <ListItem nested>
                <Toggler
                  renderToggle={({ open, setOpen }) => (
                    <ListItemButton onClick={() => setOpen(!open)}>
                      <BadgeRoundedIcon />
                      <ListItemContent>
                        <Typography level="title-sm">Coach</Typography>
                      </ListItemContent>
                      <KeyboardArrowDownIcon
                        sx={{ transform: open ? "rotate(180deg)" : "none" }}
                      />
                    </ListItemButton>
                  )}
                >
                  <List sx={{ gap: 0.5 }}>
                    <ListItem sx={{ mt: 0.5 }}>
                      <ListItemButton
                        role="menuitem"
                        component="a"
                        onClick={() =>
                          navigate(`/${pathFirstSegment}/coach-portal`)
                        }
                      >
                        My Coach Portal
                      </ListItemButton>
                    </ListItem>
                    <ListItem sx={{ mt: 0.5 }}>
                      <ListItemButton
                        role="menuitem"
                        component="a"
                        onClick={() =>
                          navigate(`/${pathFirstSegment}/coach-programmings`)
                        }
                      >
                        Programmings
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      onClick={() => navigate(`/${pathFirstSegment}/coach-PT`)}
                    >
                      <ListItemButton>Personal Trainer</ListItemButton>
                    </ListItem>
                  </List>
                </Toggler>
              </ListItem>
            )}
            {/*  ------- [User] ------ */}
            <ListItem nested>
              <Toggler
                defaultExpanded
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <ManageAccountsRoundedIcon />
                    <ListItemContent>
                      <Typography level="title-sm">User</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <ListItem sx={{ mt: 0.5 }}>
                    <ListItemButton
                      onClick={() => navigate(`/${pathFirstSegment}/profile`)}
                    >
                      My profile
                    </ListItemButton>
                  </ListItem>
                  <ListItem sx={{ mt: 0.5 }}>
                    <ListItemButton
                      onClick={() => navigate(`/${pathFirstSegment}/payments`)}
                    >
                      Subscriptions
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() => navigate(`/${pathFirstSegment}/invites`)}
                    >
                      Invites
                    </ListItemButton>
                    {Invites.length > 0 && (
                      <Chip size="sm" color="primary" variant="solid">
                        {Invites.length}
                      </Chip>
                    )}
                  </ListItem>
                </List>
              </Toggler>
            </ListItem>

            {/*  ------- [Admin] ------ */}
            {hasAdminRights && (
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(`/${pathFirstSegment}/admin-portal`)}
                >
                  <AdminPanelSettingsRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Admin</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            )}
          </List>

          {/*  ------- [Side Bar Bottom] ------ */}
          <List
            size="sm"
            sx={{
              mt: "auto",
              flexGrow: 0,
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
              "--List-gap": "8px",
              mb: 2,
            }}
          >
            <ListItem>
              <ListItemButton disabled>
                <SupportRoundedIcon />
                Support
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton disabled>
                <SettingsRoundedIcon />
                Settings
              </ListItemButton>
            </ListItem>
          </List>
          <CurrentPlanCard />
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Avatar variant="outlined" src={user.photoURL} />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm">{user.displayName}</Typography>
            <Typography level="body-xs">{user.email}</Typography>
          </Box>
          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            onClick={signOutTheUser}
          >
            <LogoutRoundedIcon />
          </IconButton>
        </Box>
      </Sheet>
      <Box
        component="main"
        className="MainContent"
        sx={{
          pt: {
            xs: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
