import React, { useEffect } from "react";
import { Chip, ChipDelete, Stack } from "@mui/joy";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";

let isInit = false;

const DatePickerWithChip = (props) => {
  const [date, setDate] = React.useState(() => {
    let dateINIT = null;
    if (props.value) {
      if (props.value.toDate) {
        dateINIT = props.value.toDate();
      } else {
        dateINIT = props.value;
      }
    }
    return dateINIT;
  });

  useEffect(() => {
    if (!isInit) {
      // to avoid running use effect at the begginign
      isInit = true;
      return;
    }

    if (props.onGetDateObj) {
      props.onGetDateObj(date);
    }
  }, [props, date]);

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {date && (
          <Chip
            size="sm"
            endDecorator={
              <>
                {props.editMode && (
                  <ChipDelete onDelete={() => setDate(null)} />
                )}
              </>
            }
          >
            {format(date, "d MMMM yyyy")}
          </Chip>
        )}
        {props.editMode && !date && (
          <DatePicker
            views={["day"]}
            readOnly={!props.editMode}
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
          />
        )}
      </LocalizationProvider>
    </Stack>
  );
};

export default DatePickerWithChip;
