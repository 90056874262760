import React from "react";

/* UI */
import { Box, Divider, Typography } from "@mui/joy";

const _DashboardBlankPage = (props) => {
  /* ------- [Returns] --------- */
  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box
        sx={{ position: "sticky", top: { sm: -100, md: -110 }, zIndex: 9995 }}
      >
        <Box
          sx={{
            px: {
              xs: 2,
              md: 6,
            },
          }}
        >
          <Typography level="h1" fontSize={35} sx={{ mt: 1, mb: 2 }}>
            {props.title}
          </Typography>
        </Box>
      </Box>
      {!props.noDivider && <Divider sx={{ mt: 2 }} />}
      {props.children}
    </Box>
  );
};

export default _DashboardBlankPage;
