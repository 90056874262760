import React, { Fragment } from "react";
import Card from "@mui/joy/Card";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  widgetBox: {
    display: "block",
    height: "auto",
    width: "auto",
  },
}));

const MyCard = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Card variant="plain">{props.children}</Card>
    </Fragment>
  );
};

export default MyCard;
