import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Stack } from "@mui/joy";

/*-----  [Authentication imports] ------ */
import AuthContext from "./../store/auth-context";

const AthleteAvatar = (props) => {
  const authCtx = useContext(AuthContext);
  const [AvatarPicURL, setAvatarPicURL] = useState(props.avatar);

  useEffect(() => {
    const loadPic = async () => {
      if (props.AthleteUID) {
        authCtx.getProfilePicURL(props.AthleteUID).then((url) => {
          setAvatarPicURL(url);
        });
      }
    };

    return loadPic();
  }, [authCtx, props.AthleteUID]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center" sx={{ mr: 1 }}>
        <Avatar
          sx={{ height: 40, width: 40 }}
          src={AvatarPicURL}
          aria-label="Coach Pic"
        />
        <Button
          size="small"
          variant="outlined"
          sx={{ color: "black", borderColor: "black" }}
        >
          {props.Name}
        </Button>
      </Stack>
    </Stack>
  );
};

export default AthleteAvatar;
