import React from "react";
import { Card } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

const MyModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ overflow: "scroll" }}
    >
      <Fade in={props.open}>
        <Card
          sx={{
            display: "inline-block",
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -30%)",
            backgroundColor: props.backgroundColor || "transparent",
          }}
        >
          {props.children}
        </Card>
      </Fade>
    </Modal>
  );
};

export default MyModal;
