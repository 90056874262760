import React, { useState } from "react";
import { Stack, Input } from "@mui/material";
import ProductCard from "../ProductCard/ProductCard";

/* ================ Cover Art Pick And Preview ================ */
const CoverArtPickAndPreview = (props) => {
  const [userCoverPicURL, setUserCoverPicURL] = useState(props.coverArtURL);

  /* ----- Reset -------- */
  if (props.reset && userCoverPicURL !== null) {
    setUserCoverPicURL(null);
  }

  /* ------- [Handlers] --------- */
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setUserCoverPicURL(URL.createObjectURL(file));

    if (props.onCoverArtChanged) {
      props.onCoverArtChanged(file);
    }
  };

  /* Product Card Data */
  const productData = {
    coverArtURL: userCoverPicURL,
    ownerUID: props.coachUID || props.ownerUID,
    ownerName: props.coachName || props.ownerName,
    pricing: props.pricing,
    name: props.title ? props.title : "",
    effortLevel: props.effortLevel ? props.effortLevel : null,
  };

  /* ------- [Return] --------- */
  return (
    <Stack alignItems={"center"}>
      <Input
        accept="image/*"
        id="cover-button-file"
        multiple
        type="file"
        sx={{ display: "none" }}
        onChange={handleFileInputChange}
        disabled={!props.editable}
      />
      <label htmlFor="cover-button-file">
        <ProductCard previewOnly disableOnClick data={productData} />
      </label>
    </Stack>
  );
};

export default CoverArtPickAndPreview;
