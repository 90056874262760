/*
 * Lemonade Training Oy copy rights all right reserved.
 */

/* -- imports   */

export default class WeekObj {
  number = null;
  // ---------------------------------
  selected = false; // if selected, then it is highlighted

  // constructor
  constructor(number = 1, selected = false) {
    this.number = number;
    this.selected = selected;
  }
}
