/*
 * Lemonade Training Oy copy rights all right reserved.
 */

export const INVITATION_TYPE = {
  toProgrammingProduct: "toProgrammingProduct",
  toCoachingSubProduct: "toCoachingSubProduct",
};

export const INVITATION_STATUS = {
  Pending: "Pending",
  Accepted: "Accepted",
  Declined: "Declined",
  Expired: "Expired",
};

export class productDetails {
  type = null;
  UID = null;
  ownerUID = null;
}

export class InviteObj {
  invitationType = null;
  // ---------------------------------
  title = null;
  senderUID = null;
  senderName = null;
  receiverUID = null;
  receiverName = null;
  message = null;
  product = null;
  status = null;

  constructor(invitationType) {
    this.invitationType = invitationType;
    this.status = INVITATION_STATUS.Pending;
    switch (invitationType) {
      case INVITATION_TYPE.toProgrammingProduct:
        this.title = "Programming Invites";
        this.product = new productDetails();
        break;
      case INVITATION_TYPE.toCoachingSubProduct:
        this.title = "Coaching Subscription Invites";
        this.product = new productDetails();
        break;
      default:
        break;
    }
  }
}
