import { Box, Divider, Typography, Stack, Button } from "@mui/joy";
import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";

/* Own Modules */
import AuthContext from "../../store/auth-context";
import { logOut } from "../../store/user-slice";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import { deleteUserAccountAndData } from "../../store/user-slice";
import MyCard from "../../components/Cards/MyCard";
import ProfilePrivacySetting from "../../components/UserProfile/ProfilePrivacySetting";
import ProfileInfo from "../../components/UserProfile/ProfileInfo";
import _DashboardBlankPage from "./_DashboardBlankPage";
import MyModal from "../../components/MyModal";

/* Routing */
import { useNavigate } from "react-router-dom";

/* =================== Profile Page ==================== */
const ProfilePage = () => {
  const user = useSelector((state) => state.user);
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const navigate = useNavigate();

  /* ------- [Functions] --------- */
  const closeDeleteAccountModal = () => {
    setDeleteAccountModal(false);
  };

  const onDeleteAccount = () => {
    setDeleteAccountLoading(true);

    deleteUserAccountAndData()
      .then(async () => {
        dispatch(
          notifyActions.sendNotification({
            type: NOTIFICATION_TYPE.success,
            message: "Your account has been deleted successfully.",
          })
        );
        await authCtx.userLogOut();
        navigate("/");
      })
      .catch((error) => {
        dispatch(
          notifyActions.sendNotification({
            type: NOTIFICATION_TYPE.error,
            message: "Failed to delete your account.",
          })
        );
      })
      .finally(() => {
        setDeleteAccountLoading(false);
        setDeleteAccountModal(false);
      });
  };

  /* ------- [Returns] --------- */
  return (
    <>
      <_DashboardBlankPage title="My Profile">
        {deleteAccountModal && (
          <MyModal open={deleteAccountModal} onClose={closeDeleteAccountModal}>
            <Typography>
              By pressing delete, you confirm that you have unsubscribed from
              all of your active paying subscriptions and would like to delete
              all your data.
            </Typography>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              spacing={4}
              sx={{ mt: 1 }}
            >
              <Button
                color="primary"
                variant="solid"
                size="lg"
                loading={deleteAccountLoading}
                onClick={onDeleteAccount}
              >
                Yes, Delete Please
              </Button>
              <Button
                color="danger"
                variant="outlined"
                size="lg"
                onClick={closeDeleteAccountModal}
              >
                Cancel
              </Button>
            </Stack>
          </MyModal>
        )}
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "800px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <MyCard>
            <Box>
              <Typography level="title-md">Personal info</Typography>
              <Typography level="body-sm">
                Customize how your profile information will show up to the
                Lemonade ecosystem.
              </Typography>
            </Box>
            <Divider />
            <ProfileInfo user={user} />
          </MyCard>
          <MyCard>
            <Box>
              <Typography level="title-md">Privacy Settings</Typography>
              <Typography level="body-sm">
                Manage your privacy preference here
              </Typography>
            </Box>
            <Divider />
            <Box>
              <ProfilePrivacySetting
                privacySettings={user.userInfoData.privacySettings}
                userUID={user.user.uid}
              />
            </Box>
          </MyCard>
          <MyCard>
            <Box>
              <Typography level="title-md">Delete My Account</Typography>
              <Typography level="body-sm">
                Delete all your data from Lemonade ecosystem
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Typography level="body-sm">
                This action is irreversible. All your data will be deleted from
                Lemonade ecosystem. Please make sure to unsubscribe if you have
                any active paid subscriptions before preceding.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                color="danger"
                endDecorator={<DeleteSweepRoundedIcon />}
                variant="outlined"
                onClick={() => setDeleteAccountModal(true)}
              >
                Delete My Account
              </Button>
            </Box>
          </MyCard>
        </Stack>
      </_DashboardBlankPage>
    </>
  );
};

export default ProfilePage;
