import React, { Fragment } from "react";
import { Grid, Divider, Stack, Typography, Button } from "@mui/joy";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import { motion, useAnimation } from "framer-motion";
import * as PRICING from "./../../constants/Pricing";
import { useDispatch } from "react-redux";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { cartActions } from "../../store/cart-slice";

/* stylig */
const useStyles = makeStyles((theme) => ({
  card: {
    width: 40,
    height: 60,
    borderRadius: 5,
    transition: "0.3s",
    background: theme.palette.background.default,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      boxShadow: "0 20px 80px -12.125px rgba(0,0,0,0.3)",
    },
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 10,
  },
}));

const artCoverVarients = {
  appear: { opacity: 1 },
};

const CartItemImage = (props) => {
  const classes = useStyles();
  const artCoverAnimate = useAnimation();

  const imageLoaded = () => {
    artCoverAnimate.start("appear");
  };

  return (
    <Fragment>
      <motion.div
        className={classes.card}
        key="image"
        whileHover={{ scale: 1.02 }}
      >
        <motion.img
          initial={{ opacity: 0 }}
          className={classes.image}
          src={props.imageSrc}
          variants={artCoverVarients}
          animate={artCoverAnimate}
          transition={{ duration: 0.5 }}
          onLoad={imageLoaded}
          width="100%"
        />
      </motion.div>
    </Fragment>
  );
};

const CartItem = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onRemoveCartItem = () => {
    dispatch(cartActions.removeItem(props.id));
  };

  /* ----- Pricing logic ----- */
  let pricingLabel = "";
  switch (props.pricing.type) {
    case PRICING.TYPE.free:
      pricingLabel = "FREE";
      break;

    case PRICING.TYPE.oneTime:
      pricingLabel = `${props.pricing.amount} €`;
      break;

    case PRICING.TYPE.subscription:
      pricingLabel = `${props.pricing.amount} €/Month `;
      break;

    default:
      pricingLabel = "ERROR";
      break;
  }

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      sx={{ mr: 1, mt: 1, minWidth: 300 }}
    >
      <Grid xs={2}>
        <CartItemImage imageSrc={props.imageSrc} />
      </Grid>
      <Grid xs={7}>
        <Stack>
          <Typography level="title-md">{props.title}</Typography>
          <Typography fontSize={8}>{props.id}</Typography>
        </Stack>
      </Grid>
      <Grid xs={2}>
        <Typography>{pricingLabel}</Typography>
      </Grid>
      <Grid xs={1}>
        <Button
          aria-label="Remove MovementItem"
          size="small"
          onClick={onRemoveCartItem}
          color="error"
        >
          <RemoveCircleOutlineIcon />
        </Button>
      </Grid>
      <Grid xs={12}>
        <Divider variant="inset" />
      </Grid>
    </Grid>
  );
};

export default CartItem;
