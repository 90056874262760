import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  CircularProgress,
} from "@mui/joy";
import Chip from "@mui/joy/Chip";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AthleteAvatar from "../AthleteAvatar";
import { INVITATION_STATUS, INVITATION_TYPE } from "../../Common/InviteObj";
import {
  loadProgrammeWithUID,
  loadProductWithUID,
} from "../../store/firebaseActions";
import { acceptOrDeclineInvitation } from "../../store/firebaseActions";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import { Typography } from "@mui/material";
import ProductCard from "../ProductCard/ProductCard";
import * as PRODUCT from "../../constants/ProductConst";

const InvitationView = (props) => {
  const dispatch = useDispatch();
  const showStatus = props.invite?.status !== INVITATION_STATUS.Pending;
  const accepted = props.invite?.status === INVITATION_STATUS.Accepted || false;
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadProduct = async () => {
      if (props.invite?.product?.UID) {
        /* if it is programming product */
        if (props.invite?.product?.type === PRODUCT.TYPE.ProgrammingProduct) {
          loadProgrammeWithUID(props.invite?.product?.UID)
            .then((newData) => {
              setProduct(newData);
            })
            .catch((error) => {
              console.log("Can't load the product]->", error.message);
            });
        }
        /* if it is coaching subscription */
        if (props.invite?.product?.type === PRODUCT.TYPE.CoachingSubscription) {
          loadProductWithUID(props.invite?.product?.UID)
            .then((newData) => {
              console.log("newData", newData);
              setProduct(newData);
            })
            .catch((error) => {
              console.log("Can't load the product]->", error.message);
            });
        }
      }
    };

    // executing use effect
    return loadProduct();
  }, [props.invite?.product?.UID]);

  /* accept or reject handler ---------------- */
  const onAcceptOrDecline = (status) => {
    setLoading(true);

    acceptOrDeclineInvitation(props.invite?.id, status)
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Invitation updated",
          message: `You have ${status} the invitation`,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "OH OH!",
          message: error.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* ----- status dependent  */
  let chipColor = "info";

  switch (props.invite?.status) {
    case INVITATION_STATUS.Pending:
      chipColor = "warning";
      break;
    case INVITATION_STATUS.Accepted:
      chipColor = "success";
      break;
    case INVITATION_STATUS.Declined:
      chipColor = "danger";
      break;
    default:
      break;
  }

  /* ------- [Returns] --------- */
  return (
    <Accordion>
      <AccordionSummary>
        {props.direction === "sent" && (
          <>
            <AthleteAvatar
              AthleteUID={props.invite?.receiverUID}
              Name={props.invite?.receiverName || "Unknown"}
            />
            <Box sx={{ flex: "1 1 auto" }} />
            <Typography level="body-sm">
              {props.invite?.title || "No Title"}
            </Typography>
            <Box sx={{ flex: "1 1 auto" }} />
            <Chip variant="solid" size="sm" color={chipColor}>
              {props.invite?.status}
            </Chip>
          </>
        )}
        {props.direction === "received" && (
          <>
            <AthleteAvatar
              AthleteUID={props.invite?.senderUID}
              Name={props.invite?.senderName || "Unknown"}
            />
            {loading && <CircularProgress size="sm" />}
            <Box sx={{ flex: "1 1 auto" }} />
            <Typography level="body-sm">
              {props.invite?.title || "No Title"}
            </Typography>
            <Box sx={{ flex: "1 1 auto" }} />
            {showStatus && (
              <Chip variant="solid" size="sm" color={chipColor}>
                {props.invite?.status}
              </Chip>
            )}
            {!showStatus && (
              <Stack direction={"row"} spacing={1}>
                <Chip
                  variant="outlined"
                  color="success"
                  size="sm"
                  endDecorator={<CheckRoundedIcon fontSize="sm" />}
                  onClick={() => onAcceptOrDecline(INVITATION_STATUS.Accepted)}
                >
                  Accept
                </Chip>
                <Chip
                  variant="outlined"
                  color="danger"
                  size="sm"
                  endDecorator={<CloseRoundedIcon fontSize="sm" />}
                  onClick={() => onAcceptOrDecline(INVITATION_STATUS.Declined)}
                >
                  Decline
                </Chip>
              </Stack>
            )}
          </>
        )}
      </AccordionSummary>
      {(accepted || props.direction === "sent") && (
        <AccordionDetails>
          {product && (
            <>
              <ProductCard
                disableOnClick
                disableAddToCart={!accepted && props.direction !== "received"}
                UID={product?.id}
                data={product.data}
              />
            </>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default InvitationView;
