import { configureStore } from "@reduxjs/toolkit";
import movLibSlice from "./movLib-slice";
import notifySlice from "./notification-slice";
import userSlice from "./user-slice";
import cartSlice from "./cart-slice";

const appReduxStore = configureStore({
  reducer: {
    movLib: movLibSlice.reducer,
    notify: notifySlice.reducer,
    user: userSlice.reducer,
    cart: cartSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default appReduxStore;
