import React, { Fragment, useState, useRef, useEffect } from "react";
import { TextField, Collapse } from "@mui/material";
import {
  Box,
  Stack,
  Divider,
  Button,
  Card,
  Input,
  Chip,
  Select,
  Option,
} from "@mui/joy";
import { makeStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MovmentItem from "./MovementItem";
import WorkoutTags from "./WorkoutTags";
import DatePickerWithChip from "../DatePickerWithChip";
import { TransitionGroup } from "react-transition-group";
import { motion, useAnimation } from "framer-motion";
import WorkoutAdditionalAction from "./WorkoutAdditionalAction";
/* Redux */
import WorkoutScore from "./WorkoutScore";
import MyModal from "../MyModal";
import AddYouTube from "../WorkoutCard/AddYouTube";
import YouTube from "react-youtube";
import { MultipleYouTubeView } from "../WorkoutCard/MultipleYouTubeView";

/* own module */
import * as PROGRAMMING from "../../constants/ProgrammingConst";
import TimelineWeekDay from "./TimelineWeekDay";

/* stylig */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: 500,
  },
  additionalActions: {
    display: "flex",
  },
}));

const additionalActionVariant = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0.0, y: -5 },
};

const WorkoutCard = (props) => {
  const classes = useStyles();
  const actionSectionAnimate = useAnimation();
  const isInitRef = useRef(false);
  const workoutUIDRef = useRef(props.UID || null);
  const cardCoachUIDRef = useRef(props.CoachUID || null);
  /* ---- [state] -----------  */
  const [tags, setTags] = useState(props.workoutData.tags || []);
  const [movements, setMovements] = useState(props.workoutData.movements || []);
  const [date, setDate] = useState(props.workoutData.date || null);
  const [title, setTitle] = useState(props.workoutData.title || "");
  const [youTubeIDs, setYouTubeIDs] = useState(
    props.workoutData.youTubeIDs || []
  ); // collection of YouTube
  const [description, setDescription] = useState(
    props.workoutData.description || ""
  );

  const [week, setWeek] = useState(props.workoutData.week || null);
  const [day, setDay] = useState(props.workoutData.day || null);

  const moveIdRef = useRef(
    movements[movements.length - 1] ? movements[movements.length - 1].id : 0
  ); // simple counter to generate unique id for movements

  // ---- show modal for adding score
  const [showAddRecord, setShowAddRecord] = useState(false);

  /* Edit Mode */
  const [editMode, setEditMode] = useState(props.editable || false);
  /* ---- [passing data to parants] -----------  */
  useEffect(() => {
    const workoutData = {
      title: title,
      tags: tags,
      date: date,
      description: description,
      movements: movements,
      youTubeIDs: youTubeIDs,
      week: week,
      day: day,
    };

    if (!isInitRef.current) {
      // to avoid running use effect at the begining
      isInitRef.current = true;
      return;
    }

    if (props.onCardUpdate) {
      props.onCardUpdate(workoutData);
    }
  }, [tags, date, movements, title, description, youTubeIDs, week, day]);

  /* Animation ------------ */
  const onMouserIsOverCard = async () => {
    actionSectionAnimate.start("visible");
  };

  const onMouserIsOutCard = async () => {
    if (editMode) return;
    actionSectionAnimate.start("hidden");
  };
  /* ----- Handlers -------- */
  const getTags = (tags) => {
    setTags(tags);
  };

  /* ------ handling card edit and save edit ----  */
  const keepPreviousWorkoutData = useRef({
    title: title,
    tags: tags,
    date: date,
    description: description,
    movements: movements,
    week: week,
    day: day,
  });

  const onEditHandle = () => {
    setEditMode(true);
  };

  const onCancelEditHandle = () => {
    setTitle(keepPreviousWorkoutData.current.title);
    setTags(keepPreviousWorkoutData.current.tags);
    setDate(keepPreviousWorkoutData.current.date);
    setDescription(keepPreviousWorkoutData.current.description);
    setMovements(keepPreviousWorkoutData.current.movements);
    setEditMode(false);
  };

  const onSaveHandle = () => {
    if (props.onSaveEdit) {
      const workoutData = {
        title: title,
        tags: tags,
        date: date,
        description: description,
        movements: movements,
        youTubeIDs: youTubeIDs,
        week: week,
        day: day,
      };

      const workout = {
        UID: workoutUIDRef.current,
        CoachUID: cardCoachUIDRef.current,
        programmingUID: props.programmingUID,
        workoutData: workoutData,
      };
      props.onSaveEdit(workout);
    }

    keepPreviousWorkoutData.current = {
      title: title,
      tags: tags,
      date: date,
      description: description,
      movements: movements,
      week: week,
      day: day,
    };
    setEditMode(false);
  };

  const onDeleteCardHandle = () => {
    if (props.onRemove) {
      const workoutData = {
        title: title,
        tags: tags,
        date: date,
        description: description,
        movements: movements,
      };

      const workout = {
        UID: workoutUIDRef.current,
        CoachUID: cardCoachUIDRef.current,
        programmingUID: props.programmingUID,
        workoutData: workoutData,
      };
      props.onRemove(workout);
    }
  };

  const onAddMovmentHandler = () => {
    moveIdRef.current++;
    const newMovment = {
      id: moveIdRef.current,
      movObj: null,
      movType: "",
      sets: 0,
      reps: 0,
      cal_meter: 0,
      percent: 0,
    };
    setMovements((prevArray) => [...prevArray, newMovment]);
  };

  /* Removing item from movment list */
  const onRemoveMovmentHandler = (id) => {
    const newMovmentList = movements.filter((item) => item.id !== id);
    setMovements(newMovmentList);
  };

  /* Getting movment data callback */
  const getMovmentDataCallBack = (movementData) => {
    const idx = movements.findIndex((mov) => mov.id === movementData.id);
    let newMovements = [...movements];
    newMovements[idx] = movementData;
    setMovements(newMovements);
  };

  /* ----- YouTube ----- */
  const onYouTubeIDsChanged = (youTubeIDs) => {
    setYouTubeIDs(youTubeIDs);
  };

  /* ------------------ RETURN ---------------------- */
  return (
    <motion.div
      className={classes.root}
      onMouseOver={onMouserIsOverCard}
      onMouseOut={onMouserIsOutCard}
    >
      <Card variant="plain" sx={{ maxWidth: 600 }}>
        <Stack direction={"row"} spacing={1}>
          <Input
            variant="outlined"
            placeholder="Workout Name"
            size="sm"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            InputProps={{ readOnly: !editMode }}
            disabled={!editMode}
          />

          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <AddYouTube
              onAddYouTubeLink={onYouTubeIDsChanged}
              readOnly={!editMode}
            />
            {props.programmingType === PROGRAMMING.TYPE.reoccurring && (
              <DatePickerWithChip
                value={date}
                onGetDateObj={(date) => setDate(date)}
                editMode={editMode}
              />
            )}
            {props.programmingType === PROGRAMMING.TYPE.timeline && (
              <TimelineWeekDay
                week={week}
                day={day}
                onWeekUpdate={(week) => {
                  setWeek(week);
                }}
                onDayUpdate={(day) => {
                  setDay(day);
                }}
                editMode={editMode}
              />
            )}
            <WorkoutTags
              value={tags}
              onTagUpdate={getTags}
              readOnly={!editMode}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={1} alignItems="center" justifyContent="flex-start">
          {props.workoutData.youTubeID && (
            <Box sx={{ mb: 1 }}>
              <YouTube
                videoId={props.workoutData.youTubeID}
                opts={{ width: 500 }}
              />
            </Box>
          )}
          {youTubeIDs.length > 0 && (
            <Box sx={{ mb: 1 }}>
              <MultipleYouTubeView youTubeIDs={youTubeIDs} />
            </Box>
          )}
          <TextField
            label="Workout Description"
            fullWidth
            multiline
            minRows={2}
            sx={{ mr: 2, ml: 2, mb: 2 }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            InputProps={{ readOnly: !editMode }}
          />
        </Stack>
        <Stack justifyContent="flex-start">
          <TransitionGroup>
            {movements.map((eachMovment) => (
              <Collapse key={eachMovment.id}>
                <MovmentItem
                  key={eachMovment.id}
                  id={eachMovment.id}
                  onRemove={onRemoveMovmentHandler}
                  onGetMovementData={getMovmentDataCallBack}
                  movementData={eachMovment}
                  editMode={editMode}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </Stack>
        <Stack sx={{ display: "flex" }}>
          {editMode && (
            <Box>
              <Button
                color="primary"
                variant="outlined"
                aria-label="Add_Movement"
                onClick={onAddMovmentHandler}
                endDecorator={<AddCircleIcon fontSize="inherit" />}
              >
                Movement
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          )}
          {!editMode && (
            <Button
              color="primary"
              variant="outlined"
              aria-label="Add_Movement"
              onClick={() => {
                setShowAddRecord(true);
              }}
              endIcon={<AddCircleIcon fontSize="inherit" />}
            >
              Record results
            </Button>
          )}
        </Stack>
      </Card>
      {workoutUIDRef.current && showAddRecord && (
        <MyModal open={showAddRecord} onClose={() => setShowAddRecord(false)}>
          <WorkoutScore
            onSubmitFinished={() => setShowAddRecord(false)}
            workoutUID={workoutUIDRef.current}
          />
        </MyModal>
      )}
      {!props.hideActions && (
        <motion.div
          animate={actionSectionAnimate}
          initial={{ opacity: 0 }}
          variants={additionalActionVariant}
          className={classes.additionalActions}
        >
          <WorkoutAdditionalAction
            workoutUID={workoutUIDRef.current}
            onSavedClicked={onSaveHandle}
            onCancelEditClicked={onCancelEditHandle}
            onEditClicked={onEditHandle}
            onDeleteClicked={onDeleteCardHandle}
            isMyWorkout={props.isMyWorkout}
            editMode={editMode}
          />
        </motion.div>
      )}
    </motion.div>
  );
};

export default WorkoutCard;
