import React, { useState } from "react";
import {
  Step,
  StepIndicator,
  Button,
  Box,
  Stepper,
  StepButton,
} from "@mui/joy";
import { useDispatch } from "react-redux";

/* own module */
import WeekObj from "../../Common/WeekObj";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";

/*
 * Note: weeks are index from 1, not 0
 * Note: days are index from 0
 */

const TimelineControlView = (props) => {
  const dispatch = useDispatch();

  // ------- state -------
  const weekUseUseStateInit = () => {
    // if numberOfWeeks is not defined, then return empty array
    if (!props.numberOfWeeks) {
      const newWeek = new WeekObj(1, true);
      if (props.onWeekSelected) props.onWeekSelected(newWeek);
      if (props.onWeekAdded) props.onWeekAdded(newWeek);
      return [newWeek];
    }

    // if weeks is defined, then return the weeks
    const tempWeeks = [];
    for (let i = 1; i <= props.numberOfWeeks; i++) {
      const newWeek = new WeekObj(i);
      if (i === 1) {
        newWeek.selected = true;
        if (props.onWeekSelected) props.onWeekSelected(newWeek);
      }
      tempWeeks.push(newWeek);
    }
    return tempWeeks;
  };

  const [weeks, setWeeks] = useState(() => weekUseUseStateInit());

  // ------- helpers -------
  const addNewWeek = () => {
    // if number of weeks is more than 15, then return
    if (weeks.length >= 12) {
      dispatch(
        notifyActions.sendNotification({
          type: NOTIFICATION_TYPE.warning,
          message: "Maximum 15 weeks are allowed",
        })
      );
      return;
    }

    const newWeek = new WeekObj(weeks.length + 1);

    // passing data to onWeekAdded
    if (props.onWeekAdded) props.onWeekAdded(newWeek);

    setWeeks((prev) => [...prev, newWeek]);
  };

  const selectedWeek = (week) => {
    // passing data to onWeekSelected
    if (props.onWeekSelected) props.onWeekSelected(week);

    // update the state
    setWeeks((prev) => {
      return prev.map((w) => {
        if (w.number === week.number) {
          w.selected = true;
        } else {
          w.selected = false;
        }
        return w;
      });
    });
  };

  // ------- render -------
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Stepper sx={{ flex: 1 }}>
          {weeks.length > 0 &&
            weeks.map((week, index) => {
              return (
                <Step
                  key={index}
                  indicator={
                    <StepIndicator
                      variant={week.selected === true ? "soft" : "outlined"}
                      color={week.selected === true ? "primary" : "neutral"}
                    >
                      {`${index + 1}`}
                    </StepIndicator>
                  }
                >
                  <StepButton
                    onClick={() => {
                      selectedWeek(week);
                    }}
                  ></StepButton>
                </Step>
              );
            })}
        </Stepper>
        {props.isItMyProgramme && (
          <Button
            variant="outlined"
            onClick={addNewWeek}
            sx={{ marginLeft: 5 }}
          >
            Add Week
          </Button>
        )}
      </Box>
    </>
  );
};

export default TimelineControlView;
