import React, { Fragment, useState } from "react";
import { Grid, Typography, Input } from "@mui/joy";

const _1RMRecordView = (props) => {
  const [record, setrecord] = useState(props.record);

  const setRecordHandler = (e) => {
    setrecord(e.target.value);
    if (props.getValue) {
      props.getValue(props.id, parseInt(e.target.value));
    }
  };

  return (
    <Fragment>
      <Grid container item sm={12} spacing={1}>
        <Grid sm={7}>
          <Typography variant="subtitle1">{props.title}</Typography>
        </Grid>
        <Grid sm={3}>
          <Input
            value={record}
            onChange={setRecordHandler}
            size="small"
            variant="soft"
            type="number"
            fullWidth
            InputProps={{
              readOnly: !props.editable,
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
        <Grid sm={1}>
          <Typography variant="caption">{props.unit}</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default _1RMRecordView;
