import React, { useState, useEffect } from "react";
import WidgetCard from "../Cards/WidgetCard";
import { CircularProgress, Stack, Divider, Typography } from "@mui/material";
import { getProgrammingSubscribers } from "../../store/firebaseActions";
import AthleteAvatar from "../AthleteAvatar";

const ViewListOfSubscribers = (props) => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);

  /* use effect to fetch the date */
  useEffect(() => {
    const loadingSubscribers = async () => {
      try {
        const subscribers = await getProgrammingSubscribers(
          props.programmingUID
        );
        setSubscribers(subscribers.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    return loadingSubscribers();
  }, []);

  return (
    <WidgetCard title="Subscribers">
      {loading && <CircularProgress />}
      {!loading &&
        subscribers.length > 0 &&
        subscribers.map((subscriber) => (
          <Stack key={subscriber.uid} spacing={1}>
            <AthleteAvatar
              key={subscriber.uid}
              AthleteUID={subscriber.uid}
              Name={subscriber.name}
            />
          </Stack>
        ))}
      {!loading && subscribers.length === 0 && (
        <Typography>No subscribers yet!</Typography>
      )}
    </WidgetCard>
  );
};

export default ViewListOfSubscribers;
