import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notifyActions } from "../store/notification-slice";
/* Mateiral UI */
import { Snackbar, Typography } from "@mui/joy";

/* icons */
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const Notification = () => {
  const dispatch = useDispatch();
  const notify = useSelector((state) => state.notify);

  if (!notify.activate) return null;

  /* ----- Alert icon --------- */
  let icon = null;
  switch (notify.severity) {
    case "info":
      icon = <InfoRoundedIcon />;
      break;
    case "warning":
      icon = <WarningAmberRoundedIcon />;
      break;
    case "error":
      icon = <ErrorOutlineRoundedIcon />;
      break;
    case "success":
      icon = <CheckCircleOutlineRoundedIcon />;
      break;
    default:
      icon = <InfoRoundedIcon />;
  }

  /* ----- Alert close handler --------- */
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(notifyActions.deactivate());
  };

  return (
    <Snackbar
      variant="plain"
      open={notify.activate}
      autoHideDuration={5000}
      onClose={handleAlertClose}
      color={notify.severity}
      startDecorator={icon}
    >
      <div>
        <Typography level="title-md">{notify.title}</Typography>
        {notify.message}
      </div>
    </Snackbar>
  );
};

export default Notification;
