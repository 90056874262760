import { Box, Stack, Typography, Divider } from "@mui/joy";
import React, { useRef } from "react";
import {
  loadProgrammeWithUID,
  loadUserSubInfoForProgram,
} from "../store/firebaseActions";

/* store */
import appReduxStore from "../store/appReduxStore";

/* Routing */
import { useParams, useLoaderData } from "react-router-dom";

/* reudx */
import MyCard from "../components/Cards/MyCard";
import WorkoutProgram from "../components/WorkoutProgram";

/* ============= [Loader] ====================== */
/*
 * It will be called by the router when the page is loaded
 * It will load the data for the page and pass it to the page component
 */
export async function loader({ params }) {
  const { UID } = params;
  const programUID = UID;
  const userUID = appReduxStore.getState().user?.user?.uid;

  const programData = await loadProgrammeWithUID(programUID);
  if (!userUID || !programData) {
    return null;
  }

  /* check if isItMyProgramme */
  let isItMyProgramme =
    (userUID === programData.data.CoachUID ? true : false) ||
    (userUID === programData.data.ownerUID ? true : false);

  // If it is not my programme, then check if user's subscription info
  let userSubInfo = null;
  if (!isItMyProgramme) {
    userSubInfo = await loadUserSubInfoForProgram(userUID, programUID);
    if (!userSubInfo) {
      return null;
    }
  }

  return {
    programData: { ...programData.data, programmingUID: programData.id },
    userSubInfo,
    isItMyProgramme,
  };
}

/* =================== ViewWorkoutProgramme ==================== */
const ViewWorkoutProgramme = () => {
  const params = useParams();
  const programmingRef = useRef(null);
  const isItMyProgramme = useRef(false);
  const userSubInfo = useRef(null);

  /* Load the programming */
  const loaderData = useLoaderData();
  if (loaderData) {
    programmingRef.current = loaderData.programData;
    userSubInfo.current = loaderData.userSubInfo;
    isItMyProgramme.current = loaderData.isItMyProgramme;
  }

  /* -------- Return ----------- */
  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box
        sx={{ position: "sticky", top: { sm: -100, md: -110 }, zIndex: 9995 }}
      >
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h1" fontSize={35} sx={{ mt: 1, mb: 2 }}>
            {`${programmingRef.current?.name ?? ""}`}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />
      <Stack
        spacing={3}
        sx={{
          display: "flex",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        {!programmingRef.current && (
          <MyCard>
            <Box>
              <Typography level="title-md">Something Wrong!!</Typography>
              <Typography level="body-sm">
                Either such programming not exist or you don't have access. Make
                sure you are signed in. if problem persist, please contact us.
              </Typography>
            </Box>
          </MyCard>
        )}
        {programmingRef.current && (
          <WorkoutProgram
            viewOnly={!isItMyProgramme.current}
            isItMyProgramme={isItMyProgramme.current}
            programData={programmingRef.current}
            programmingUID={params.UID}
            subInfo={userSubInfo.current}
          />
        )}
      </Stack>
    </Box>
  );
};

export default ViewWorkoutProgramme;
