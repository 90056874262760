import React, { useContext, useState } from "react";
import {
  Box,
  Input,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Button,
} from "@mui/joy";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { useDispatch } from "react-redux";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";

/* Authentication imports */
import AuthContext from "../../store/auth-context";

const ProfileInfo = (props) => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const [profileInfoChanged, setProfileInfoChanged] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const userName_INIT =
    authCtx.user && authCtx.user?.displayName ? authCtx.user.displayName : "";
  const userPic_INIT = authCtx.user?.photoURL ? authCtx.user.photoURL : "";

  const [userPicURL, setuserPicURL] = useState(userPic_INIT);
  const [userName, setuserName] = useState(userName_INIT);
  const [userImageFile, setUserImageFile] = useState();

  /* ------- [Handlers] --------- */
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setUserImageFile(file);
    setuserPicURL(URL.createObjectURL(file));
    setProfileInfoChanged(true);
  };

  const handleFullNameChange = (event) => {
    setuserName(event.target.value);
    setProfileInfoChanged(true);
  };

  const handleUpdateProfile = async () => {
    // upload the image to storage
    setUpdateInProgress(true);
    let downloadURL = userPicURL;
    if (userImageFile) {
      downloadURL = await authCtx.uploadUserProfilePic(userImageFile);
    }

    authCtx.updateUserProfile(userName, downloadURL).then(() => {
      setuserPicURL(downloadURL);

      const notification = {
        severity: NOTIFICATION_TYPE.success,
        title: "All set!",
        message: "Your profile information updated",
      };
      dispatch(notifyActions.sendNotification(notification));
      setProfileInfoChanged(false);
      setUpdateInProgress(false);
    });
  };

  return (
    <Stack spacing={1}>
      <Stack
        direction={"row"}
        spacing={3}
        sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
      >
        <Stack direction={"column"}>
          <Input
            accept="image/*"
            id="icon-button-file"
            multiple
            type="file"
            sx={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          <label htmlFor="icon-button-file">
            <IconButton aria-label="upload picture" component="span">
              <Avatar
                src={userPicURL}
                sx={{ width: 110, height: 110 }}
              ></Avatar>
            </IconButton>
          </label>
        </Stack>
        <Stack spacing={1} sx={{ flexGrow: 1 }}>
          <Typography>Full Name</Typography>
          <Input
            variant="outlined"
            onChange={handleFullNameChange}
            value={userName}
            placeholder="write here..."
          />
          <Typography variant="subtitle1">
            {props.user.claims?.role || "unknown"}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction={"row"}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="outlined"
          disabled={!profileInfoChanged}
          onClick={handleUpdateProfile}
          endDecorator={<ContactPageIcon />}
          loading={updateInProgress}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default ProfileInfo;
