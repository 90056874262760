import React, { Fragment, useState, useEffect, useRef } from "react";
import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { loadWorkoutCard } from "../store/firebaseActions";
import WorkoutCard from "../components/Workout/WorkoutCard";
import { motion } from "framer-motion";
import { makeStyles } from "@mui/styles";
import ViewAthletesScoresWidget from "../components/Workout/ViewAthletesScoresWidget";

/* stylig */
const useStyles = makeStyles((theme) => ({
  mainPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

/* custom hook to pars the status pram*/
const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const WorkoutCardPortal = () => {
  const classes = useStyles();
  let paramQuery = useQuery();
  const workoutCardUID = useRef(paramQuery.get("UID"));
  const [cardData, setCardData] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadCard = async () => {
      if (workoutCardUID.current) {
        const data = await loadWorkoutCard(workoutCardUID.current);
        if (data.exists()) {
          setCardData(data.data());
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }

      setLoading(false);
    };

    return loadCard();
  }, [workoutCardUID.current]);

  return (
    <Fragment>
      <motion.div className={classes.mainPaper}>
        <Divider sx={{ width: "100%" }} textAlign="left">
          Workout Card Portal
        </Divider>
        {Loading && <CircularProgress color="primary" sx={{ m: 5 }} />}
        {cardData && (
          <WorkoutCard workoutData={cardData.workoutData} editable={false} />
        )}
        {error && <Typography>No WorkoutCard Found</Typography>}
        <Grid container sx={{ mt: 2 }}>
          <Grid xs={1} />
          <Grid xs={5}>
            <ViewAthletesScoresWidget workoutCardUID={workoutCardUID.current} />
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>
      </motion.div>
    </Fragment>
  );
};

export default WorkoutCardPortal;
