import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hero: {
    display: "flex",
    flexGrow: 1,
  },
  getItOnGoogle: {
    width: "160px",
    height: "60px",
  },
  stores: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

/*
 * ======================================================
 * Featuring App
 * ======================================================
 */
const AppStoreAndGooglePlay = () => {
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <div className={classes.stores}>
        <a href="https://apps.apple.com/fi/app/lemonade-training/id6444232539?itsct=apps_box_badge&amp;itscg=30200">
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1678233600"
            alt="Download on the App Store"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.MasinaComputer.TheLemonadeTraining&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            className={classes.getItOnGoogle}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </div>
    </div>
  );
};

export default AppStoreAndGooglePlay;
