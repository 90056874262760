import {
  Box,
  Divider,
  Typography,
  Stack,
  AccordionGroup,
  Button,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/joy/AccordionDetails";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Accordion, { accordionClasses } from "@mui/joy/Accordion";

/* Firebase */
import { collection, onSnapshot } from "firebase/firestore";
import { firebaseDB, COLLECTIONS } from "../../store/firebaseApp";

/* Own Modules */
import _DashboardBlankPage from "./_DashboardBlankPage";
import MyCard from "../../components/Cards/MyCard";
import ActiveSubscriptionItem from "../../components/UserProfile/ActiveSubscriptionItem";

/* =================== Profile Page ==================== */
const PaymentsAndSubscriptions = () => {
  const user = useSelector((state) => state.user);
  const [subscriptions, setSubscriptions] = useState([]);

  const userUID = user.user.uid;

  // useEffect to subscribe to user's subscriptions collection in database
  useEffect(() => {
    const subRef = collection(
      firebaseDB,
      COLLECTIONS.Users,
      userUID,
      COLLECTIONS.Subscriptions
    );

    const unsubscribe = onSnapshot(subRef, (querySnapshot) => {
      const subs = [];
      querySnapshot.docs.forEach((doc) => {
        const sub = { id: doc.id, ...doc.data() };
        subs.push(sub);
      });
      setSubscriptions(subs);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  /* ------- [Returns] --------- */
  return (
    <_DashboardBlankPage title="Payments and Subscriptions">
      <Stack
        spacing={4}
        sx={{
          display: "flex",
          maxWidth: "800px",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <MyCard>
          <Box>
            <Typography level="title-md">Subscriptions</Typography>
            <Typography level="body-sm">
              See information of your subscription and manage them.
            </Typography>
          </Box>
          <Divider />
          {subscriptions.length === 0 && (
            <Typography level="body-sm">
              You don't have any subscription yet.
            </Typography>
          )}

          <Box sx={{ display: "flex", flexDirection: "row", p: 1 }}>
            {subscriptions.length > 0 && (
              <AccordionGroup
                variant="outlined"
                transition="0.2s ease"
                sx={{
                  borderRadius: "md",
                  [`& .${accordionClasses.root}`]: {
                    "& button:hover": {
                      background: "transparent",
                    },
                  },
                  [`& .${accordionSummaryClasses.button}:hover`]: {
                    bgcolor: "transparent",
                  },
                  [`& .${accordionDetailsClasses.content}`]: {
                    boxShadow: (theme) =>
                      `inset 0 1px ${theme.vars.palette.divider}`,
                    [`&.${accordionDetailsClasses.expanded}`]: {
                      paddingBlock: "0.75rem",
                    },
                  },
                }}
              >
                {subscriptions.map((sub) => (
                  <ActiveSubscriptionItem subscription={sub} key={sub.id} />
                ))}
              </AccordionGroup>
            )}
          </Box>
        </MyCard>
      </Stack>
    </_DashboardBlankPage>
  );
};

export default PaymentsAndSubscriptions;
