import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AppImageSplash from "../../assets/App-phone_1.png";
import AppImageTodaysView from "../../assets/App-phone_3.png";
import AppStoreAndGooglePlay from "./AppStoreAndGooglePlay";

const useStyles = makeStyles((theme) => ({
  hero: {
    display: "flex",
    flexGrow: 1,
  },
  divWrap: {
    paddingTop: 25,
    paddingBottom: 10,
  },
  cards: {
    transformOrigin: "bottom right",
    width: 200,
    height: 406,
  },
}));

/* -------- [Animations] --------- */
const animationVarient = {
  bottomCard: { rotate: 0, x: 0 },
  center: { rotate: 0, x: -15 },
  topCard: { rotate: 10, x: -25, y: 10 },
};

/*
 * ======================================================
 * AppInPhone
 * ======================================================
 */
const AppInPhone = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <motion.div
      className={classes.divWrap}
      initial={{ opacity: 1 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      {!smallScreen && (
        <motion.img
          initial="bottomCard"
          animate="center"
          className={classes.cards}
          src={AppImageSplash}
          variants={animationVarient}
        />
      )}
      <motion.img
        initial="bottomCard"
        animate={smallScreen ? "center" : "topCard"}
        className={classes.cards}
        src={AppImageTodaysView}
        variants={animationVarient}
      />
    </motion.div>
  );
};
/*
 * ======================================================
 * Featuring App
 * ======================================================
 */
const FeaturingApp = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.content}
      >
        <Typography
          variant="h3"
          sx={{ alignSelf: "center", fontSize: smallScreen ? 30 : 40, mb: 2 }}
        >
          Your Workout at your finger tips
        </Typography>
        <Grid container justifyContent="center">
          <Grid item lg={1} md={0} />
          <Grid item lg={5} md={10}>
            <Typography variant="h5">How It Works:</Typography>
            <Typography>-1: Sign up</Typography>
            <Typography>
              -2: Buy your workout programming from the website
            </Typography>
            <Typography>
              -3: Access it through our Web app or Mobile App
            </Typography>

            <AppStoreAndGooglePlay />
          </Grid>
          <Grid item lg={6} md={10}>
            <AppInPhone />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FeaturingApp;
