import React from "react";
import { Grid, Typography } from "@mui/joy";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LemonadeTrainingText from "../../assets/LOGO_trademark.PNG";
import HERO_IMAGE from "../../assets/BACKGROUND_LANDINGPAGE.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    //display: "block",
    //height: "700px",
  },
  hero: {
    backgroundImage: `url(${HERO_IMAGE})`,
    backgroundPositionY: "center",
    backgroundSize: "cover",
    opacity: 1,
    display: "flex",
    flexGrow: 1,
    height: "900px",
    background: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "150%",
    },
  },
  hereLogo: {
    width: 400,
    height: 150,
    [theme.breakpoints.down("sm")]: {
      width: 250,
      height: 100,
    },
  },
  content: {
    paddingTop: 120,
  },
}));

const Hero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <Grid container className={classes.content} sx={{ mt: 5 }} lg={12}>
          <Grid lg={3} sm={1} />
          <Grid
            container
            lg={6}
            md={5}
            sm={6}
            direction="column"
            alignItems="center"
          >
            <img
              src={LemonadeTrainingText}
              alt="Lemonade Training Oy"
              className={classes.hereLogo}
            />
            {smallScreen && (
              <Typography color="white" sx={{ fontSize: 15, color: "white" }}>
                Squeeze out your potential
              </Typography>
            )}
            {!smallScreen && (
              <Typography sx={{ fontSize: 25, color: "white" }}>
                Squeeze out your potential
              </Typography>
            )}
          </Grid>
          {!smallScreen && <Grid lg={1} md={0}></Grid>}
          <Grid lg={1} sm={1} />
        </Grid>
      </div>
    </div>
  );
};

export default Hero;
