import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import {
  firebaseDB,
  firebaseStorage,
  firebaseFunctions,
  movementCollection,
  workoutCollection,
  COLLECTIONS,
} from "./firebaseApp";
import { httpsCallable } from "firebase/functions";

/*
 * Uploading programming cover art
 */
export const uploadCoverArt = async (file, userUID, productUID = null) => {
  /* -------- [preCheck] --- */
  if (!file) {
    return "";
  }
  /* ----------------------- */
  const coverArtRef = ref(
    firebaseStorage,
    `users/${userUID}/CoverArts/${productUID ? productUID : file.name}`
  );

  const snapshot = await uploadBytes(coverArtRef, file);
  const downloadURL = await getDownloadURL(snapshot.ref);
  return downloadURL;
};

/*
 * Creating new workout card
 */
export const newWorkoutCards = async (workoutCardData) => {
  const newWorkoutCard = httpsCallable(
    firebaseFunctions,
    "createNewWorkoutCard"
  );
  const ret = await newWorkoutCard(workoutCardData);
  return ret;
};

/*
 * Creating new workout card
 */
export const updateWorkoutCard_Backend = async (card) => {
  const update = httpsCallable(firebaseFunctions, "updateWorkoutCard");
  const ret = await update(card);
  return ret;
};

/*
 * Delete workout card from programming
 */
export const deleteWorkoutFromProgramming = async (card) => {
  const deleteWC = httpsCallable(
    firebaseFunctions,
    "deleteWorkoutCardFromProgramming"
  );
  const ret = await deleteWC(card);
  return ret;
};

/* updating current workout card */
export const updateWorkoutCard = async (UID, workoutCardData) => {
  const docRef = doc(firebaseDB, COLLECTIONS.WorkoutCards, UID);
  return await setDoc(docRef, workoutCardData);
};

/*
 * Update programming weeks in Timeline mode
 */
export const updateProgrammingWeeks = async (UID, weeks) => {
  const docRef = doc(firebaseDB, COLLECTIONS.Programmings, UID);
  return await updateDoc(docRef, { weeks: weeks });
};

/*
 * load specific programming
 */
export const loadProgrammeWithUID = async (programUID) => {
  const docRef = doc(firebaseDB, COLLECTIONS.Programmings, programUID);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { id: docSnap.id, data: docSnap.data() };
  } else {
    //TODO: do the error handling here
    console.log("No such document!");
  }
};

export const loadWorkoutCard = async (UID) => {
  const docRef = doc(firebaseDB, COLLECTIONS.WorkoutCards, UID);
  const docSnap = await getDoc(docRef);
  return docSnap;
};

/* load workout cards of specific date range */
export const loadWorkoutCard_DateRange = async (
  programmingUID,
  startDate,
  endDate
) => {
  const queryRef = query(
    workoutCollection,
    where("programmingUID", "==", programmingUID),
    where("workoutData.date", ">=", startDate),
    where("workoutData.date", "<=", endDate)
  );

  const querySnapshot = await getDocs(queryRef);
  return querySnapshot;
};

/* load workout cards of specific date range */
export const loadWorkoutCard_week = async (programmingUID, week) => {
  const queryRef = query(
    workoutCollection,
    where("programmingUID", "==", programmingUID),
    where("workoutData.week", "==", week)
  );

  const querySnapshot = await getDocs(queryRef);
  return querySnapshot;
};

/* load all workout cards of specific */
export const loadAllWorkoutCard = async (programmingUID) => {
  const queryRef = query(
    workoutCollection,
    where("programmingUID", "==", programmingUID)
  );

  const querySnapshot = await getDocs(queryRef);
  return querySnapshot;
};

/*
 * Update control setting of a programming
 */
export const updateProgrammingControlSetting = async (controlSetting) => {
  const updateControlSetting = httpsCallable(
    firebaseFunctions,
    "updateProgrammingControlSettings"
  );
  const ret = await updateControlSetting(controlSetting);
  return ret;
};

/*
 * get list of subscriber UIDs
 */
export const getProgrammingSubscribersUIDs = async (programmingUID) => {
  const getSubscribers = httpsCallable(
    firebaseFunctions,
    "getProgrammingSubscribersUIDs"
  );

  const ret = await getSubscribers({ programmingUID: programmingUID });
  return ret;
};

/*
 * get subscription info of a user for a programming
 */
export const loadUserSubInfoForProgram = async (userUID, programmingUID) => {
  const userRef = doc(
    firebaseDB,
    COLLECTIONS.Users,
    userUID,
    COLLECTIONS.UserLibrary,
    programmingUID
  );
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    return data;
  } else {
    return null;
  }
};

/*
 * get list of subscriber
 */
export const getProgrammingSubscribers = async (programmingUID) => {
  const getSubscribers = httpsCallable(
    firebaseFunctions,
    "getProgrammingSubscribers"
  );

  const ret = await getSubscribers({ programmingUID: programmingUID });
  return ret;
};

/* ===========================================
 * User Management
 * ===========================================
 */
/*
 * set lemonade admin custom claim to the user by email
 */
export const setLemonadeAdminAccessByEmail = async (email) => {
  const assignLemonadeAdmin = httpsCallable(
    firebaseFunctions,
    "setLemonadeAdminAccessByEmail"
  );
  const ret = await assignLemonadeAdmin({ email: email });
  return ret;
};

/*
 * set coach custom claim to the user by email
 */
export const setCoachAccessByEmail = async (email) => {
  const assignCoach = httpsCallable(firebaseFunctions, "setCoachAccessByEmail");
  const ret = await assignCoach({ email });
  return ret;
};

/*
 * send invitation to the user by email
 */
export const sendInvitationByEmail = async (email, invitation) => {
  const sendInvitation = httpsCallable(
    firebaseFunctions,
    "sendInvitationByEmail"
  );
  const ret = await sendInvitation({ email, invitation });
  return ret;
};

/*
 * accept or decline invitation
 */
export const acceptOrDeclineInvitation = async (invitationUID, status) => {
  const acceptDecline = httpsCallable(
    firebaseFunctions,
    "acceptOrDeclineInvitation"
  );
  const ret = await acceptDecline({ invitationUID, status });
  return ret;
};

/* ===========================================
 * Product Management
 * ===========================================
 */
/*
 * Creating Product
 * @param product: should be of type ProductObj
 */
export const createProduct = async (product, isLemonadeProduct = false) => {
  /* creating the stripe product and price */
  const createProduct = httpsCallable(firebaseFunctions, "createProduct");

  let stripeProduct = null;
  let stripePrice = null;
  await createProduct({ product, isLemonadeProduct }).then((result) => {
    // Read result of the Cloud Function.
    stripeProduct = result.data.stripeProduct;
    stripePrice = result.data.stripePrice;
  });

  return stripeProduct.id;
};

/*
 * update product Cover Art
 * @param productUID: UID of the product
 * @param coverArtURL: URL of the cover art
 */
export const updateProductCoverURL = async (
  productUID,
  productType,
  coverArtURL
) => {
  const updateCoverArt = httpsCallable(
    firebaseFunctions,
    "updateProductCoverURL"
  );
  return updateCoverArt({ productUID, productType, coverArtURL });
};

/*
 * load users own products
 */
export const loadUsersOwnProducts = async (productType, flags) => {
  const loadProducts = httpsCallable(firebaseFunctions, "loadUsersOwnProducts");
  const ret = await loadProducts({ productType, flags });
  return ret;
};

/*
 * load specific programming
 */
export const loadProductWithUID = async (productUID) => {
  const docRef = doc(firebaseDB, COLLECTIONS.CoachingSubscriptions, productUID);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { id: docSnap.id, data: docSnap.data() };
  } else {
    //TODO: do the error handling here
    console.log("No such document!");
  }
};

/* ===========================================
 * Movement Library
 * ===========================================
 */

export const loadMovementLibrary = async () => {
  const data = [];
  const querySnapshot = await getDocs(movementCollection);
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

/* ===========================================
 * Subscriptions
 * ===========================================
 */
export const registerFreeProgram = async (userUID, programUID, programName) => {
  const register = httpsCallable(firebaseFunctions, "registerFreeProgramming");
  return register({
    userUID: userUID,
    programUID: programUID,
    programName: programName,
  });
};

/*
 * load user subscriptions
 */
export const getUserLibrary = async (userUID) => {
  const load = httpsCallable(firebaseFunctions, "getUserProgrammings");
  return load({ userUID });
};

/*
 * cancel subscription
 * @param subscriptionId: The ID of the subscription object.
 * @param stripeAccountID: The ID of the connected account that the subscription belongs to.
 * stripeAccountID = null means it is Lemonade product
 */
export const cancelSubscription = async (
  subscriptionId,
  stripeAccountID = null
) => {
  const cancel = httpsCallable(firebaseFunctions, "cancelSubscription");
  return cancel({ subscriptionId, stripeAccountID });
};
/* ===========================================
 * Workout Results
 * ===========================================
 */

/*
 * Read workout results
 */
export const loadWorkoutResults = async (workoutUID, userUID) => {
  const load = httpsCallable(firebaseFunctions, "getWorkoutCardScoresWithUID");
  return load({ workoutUID: workoutUID, userUID: userUID });
};

/* ===========================================
 * Customer Landing page
 * ===========================================
 */

/*
 * Check if new name exist already or can be used
 */
export const checkNewLandingPageName = async (pageName) => {
  const checkNewName = httpsCallable(
    firebaseFunctions,
    "checkNewLandingPageName"
  );
  return checkNewName({ pageName: pageName });
};

/*
 * Create Landing Page
 */
export const createLandingPageBackend = async (pageName) => {
  const createPage = httpsCallable(firebaseFunctions, "createLandingPage");
  return createPage({ pageName: pageName });
};

/*
 * Get Landing Page info
 */
export const getLandingPageData = async () => {
  const getPage = httpsCallable(firebaseFunctions, "getLandingPage");
  return getPage();
};

/*
 * update Landing Page info
 */
export const updateLandingPageData = async (info) => {
  const updatePage = httpsCallable(firebaseFunctions, "updateLandingPage");
  return updatePage(info);
};

/*
 * Get Landing Page info
 */
export const getLandingPageDataByName = async (pageName) => {
  const getPage = httpsCallable(firebaseFunctions, "getLandingPageBasedOnName");
  return getPage({ pageName: pageName });
};

/*
 * Get Landing Page programmings
 */
export const getLandingPageProgrammings = async (ownerUID) => {
  const getProgrammings = httpsCallable(
    firebaseFunctions,
    "getLandingPageProgrammings"
  );
  return getProgrammings({ ownerUID: ownerUID });
};

/*
 * Upload landing page banner
 */
export const uploadLandingPageBanner = async (userUID, file) => {
  const bannerPicRef = ref(
    firebaseStorage,
    `users/${userUID}/LandingPageBanner`
  );
  const snapshot = await uploadBytes(bannerPicRef, file);
  const downloadURL = await getDownloadURL(snapshot.ref);
  return downloadURL;

  // TODO: add error handling
};
