import { createSlice } from "@reduxjs/toolkit";
import { firebaseDB, COLLECTIONS } from "./firebaseApp";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { deleteUser, getAuth } from "firebase/auth";
import { notifyActions, NOTIFICATION_TYPE } from "./notification-slice";

const userINIT = {
  user: null,
  claims: null,
  isLoggedIn: false,
  userInfoData: {},
};

const userSlice = createSlice({
  name: "user",
  initialState: userINIT,
  reducers: {
    logOut(state) {
      state.user = null;
      state.claims = null;
      state.isLoggedIn = false;
      state.userInfoData = {};
    },
    setUser(state, actions) {
      state.user = actions.payload;
      state.isLoggedIn = !!actions.payload;
    },
    setClaims(state, actions) {
      state.claims = actions.payload;
    },
    setUserInfoData(state, actions) {
      state.userInfoData = { ...actions.payload };
    },
    setUser1RM(state, actions) {
      state.userInfoData.Records_1RM = actions.payload;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;

/* ==========================
 * Async actions
 * ========================= */

/*
 * Delete user account and data
 */
export const deleteUserAccountAndData = () => {
  const auth = getAuth();
  // Delete users data in "Users" Collection
  // will be deleted on auth trigger in firebase function in web site backend.
  return deleteUser(auth.currentUser);
};

/*
 * Load user Info Database
 */
export const loadUserInfoData = (userUID) => {
  return async (dispatch) => {
    const userRef = doc(firebaseDB, COLLECTIONS.Users, userUID);
    const _1RMColl = collection(
      firebaseDB,
      COLLECTIONS.Users,
      userUID,
      COLLECTIONS._1RM
    );

    /* Getting the User Info Data */
    const docSnap = await getDoc(userRef);

    const _1RMSnap = await getDocs(_1RMColl);
    const _1RMData = [];

    _1RMSnap.forEach((doc) => {
      _1RMData.push({
        id: doc.id,
        name: doc.data().name,
        type: doc.data().type,
        measureType: doc.data().measureType,
        _1RM: doc.data()._1RM,
      });
    });

    const userInfoTemp = { ...docSnap.data(), Records_1RM: _1RMData };
    dispatch(userActions.setUserInfoData(userInfoTemp));
  };
};

/*
 * Load user Info Database
 */
export const loadUser1RM = (userUID) => {
  return async (dispatch) => {
    const _1RMColl = collection(
      firebaseDB,
      COLLECTIONS.Users,
      userUID,
      COLLECTIONS._1RM
    );

    const _1RMSnap = await getDocs(_1RMColl);
    const _1RMData = [];

    _1RMSnap.forEach((doc) => {
      _1RMData.push({
        id: doc.id,
        name: doc.data().name,
        type: doc.data().type,
        measureType: doc.data().measureType,
        _1RM: doc.data()._1RM,
      });
    });

    dispatch(userActions.setUser1RM(_1RMData));
  };
};

/*
 * set user privacy settings
 */
export const uploadUserPrivacySettings = (userUID, privacySettings) => {
  return async (dispatch) => {
    const userRef = doc(firebaseDB, COLLECTIONS.Users, userUID);

    await updateDoc(userRef, {
      privacySettings: privacySettings,
    })
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Update successful",
          message: "Your privacy setting updated",
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "Oooobs! Privacy setting update ERROR",
          message: error.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      });
  };
};
