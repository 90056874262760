import React from "react";
import { useState, useRef } from "react";
import YouTube from "react-youtube";
import Pagination from "@mui/material/Pagination";

export const MultipleYouTubeView = (props) => {
  const [youTubeIDs, setYouTubeIDs] = useState(props.youTubeIDs || []);
  const [page, setPage] = React.useState(1);

  // if youTubeIDs updated through the props, then update the useState
  React.useEffect(() => {
    setYouTubeIDs(props.youTubeIDs);
  }, [props.youTubeIDs]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <YouTube videoId={youTubeIDs[page - 1]} opts={{ width: 500 }} />
      <Pagination
        count={youTubeIDs.length}
        color="primary"
        size="small"
        page={page}
        onChange={handleChange}
      />
    </>
  );
};
