/* ------ This is the Global enum definition.---------
 * Note: DO NOT change enum definition unless it won't break anything
 */

/* ------ [Workout Type] -------- */
export const TYPE = ["Strength", "Metcon", "EMOM", "AMRAP"];

/* score type definition also used on backend for sorting,
 * check: getWorkoutCardScores on firebase functions */
export const SCORE_TYPE = {
  none: "none", // users will get to add comments only, no leader board
  score: "score", // could be any calculation desired
  //kg: "kg",
  time: "time",
};

export const CARD_INIT = {
  title: "",
  tags: [],
  date: null,
  description: "",
  movements: [],
  scoreType: SCORE_TYPE.none,
};
