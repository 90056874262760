import { firebaseFunctions } from "./firebaseApp";
import { httpsCallable } from "firebase/functions";

/*
 * Start Stripe Onboarding
 */
export const startStripeOnboarding = async () => {
  const onboarding = httpsCallable(
    firebaseFunctions,
    "stripeAccountOnboarding"
  );
  const ret = await onboarding();
  return ret;
};

/*
 * Get Stripe Account info
 */
export const getStripeAccountInfo = async () => {
  const accountInfo = httpsCallable(firebaseFunctions, "getStripeAccountInfo");
  const ret = await accountInfo();
  return ret;
};

/*
 * Create Stripe Account Link
 */
export const createStripeAccountLink = async (accountID) => {
  const accountLink = httpsCallable(
    firebaseFunctions,
    "createStripeAccountLink"
  );
  const ret = await accountLink({ accountID: accountID });
  return ret;
};

/*
 * Create Stripe Checkout Session
 */
export const createStripeCheckoutSession = async (payload) => {
  const checkoutSession = httpsCallable(
    firebaseFunctions,
    "createStripeCheckoutSession"
  );
  const ret = await checkoutSession(payload);
  return ret;
};
