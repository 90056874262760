import React from "react";
import { useSelector } from "react-redux";
import { Card, Typography, Stack, Box } from "@mui/joy";

// ============== Current Plan Card ==============
const CurrentPlanCard = () => {
  const access = useSelector((state) => state.user.claims);
  const hasAdminRights = access.admin || access.lemonadeAdmin;
  const hasCoachRights = access.coach || access.admin || access.lemonadeAdmin;

  let currentPlanText = "Lemonade Athlete (Free)";
  let bgColor = "warning";

  if (hasCoachRights) {
    currentPlanText = "Lemonade Coach";
    bgColor = "primary";
  }

  if (hasAdminRights) {
    currentPlanText = "Lemonade Admin";
    bgColor = "neutral";
  }

  return (
    <Card
      invertedColors
      variant="soft"
      size="sm"
      color={bgColor}
      sx={{ boxShadow: "none" }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-sm">Current Plan</Typography>
      </Stack>
      <Typography level="body-xs">{currentPlanText}</Typography>
    </Card>
  );
};

export default CurrentPlanCard;
