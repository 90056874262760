import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, MenuItem } from "@mui/material";
import { Stack, Button, Input, Typography } from "@mui/joy";

/* own modules */
import { addNewMovement, movLibActions } from "../../store/movLib-slice.js";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import * as MOVEMENT from "../../constants/MovementConst.js";
import MyModal from "../MyModal.js";
import { loadMovLib } from "../../store/movLib-slice.js";

const movINIT = {
  name: "",
  type: MOVEMENT.TYPE.None,
  measureType: MOVEMENT.MEASURE.None,
};

const AddNewMovementModal = () => {
  const dispatch = useDispatch();
  const showCard = useSelector((state) => state.movLib.showModal);
  const [loading, setLoading] = useState(false);
  const [movement, setMovement] = useState(movINIT);

  useEffect(() => {
    if (showCard) {
      setMovement(movINIT);
    }
  }, [showCard]);

  /* Handlers ------ */
  const moveNameHandler = (event) => {
    setMovement((prev) => ({ ...prev, name: event.target.value }));
  };

  const categoryChangeHandler = (event) => {
    setMovement((prev) => ({ ...prev, type: event.target.value }));
  };

  const measureChangeHandler = (event) => {
    setMovement((prev) => ({ ...prev, measureType: event.target.value }));
  };

  const addBtnHandler = () => {
    setLoading(true);
    addNewMovement(movement)
      .then((mov) => {
        dispatch(loadMovLib());
        dispatch(movLibActions.showModal(false));
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: `Movement ${movement.name}`,
          message: "New movement created successfully!",
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "Can't create new movement!",
          message: error.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    dispatch(movLibActions.showModal(false));
  };

  return (
    <MyModal open={showCard} onClose={handleClose} backgroundColor="white">
      <Stack sx={{ m: 1 }} spacing={2}>
        <Typography>Create New Movement!</Typography>
        <Stack display="flex" direction="row" spacing={2}>
          <TextField
            label="Movement Name"
            size="small"
            variant="standard"
            value={movement.name || ""}
            onChange={moveNameHandler}
            sx={{ minWidth: 250 }}
          />
          <TextField
            select
            id="Measure"
            label="Measure"
            value={movement.measureType || ""}
            onChange={measureChangeHandler}
            variant="standard"
            size="small"
            sx={{ minWidth: 100 }}
          >
            {Object.values(MOVEMENT.MEASURE).map((eachType, index) => (
              <MenuItem key={index} value={eachType}>
                {eachType}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            id="Category"
            label="Category"
            value={movement.type || ""}
            onChange={categoryChangeHandler}
            variant="standard"
            size="small"
            sx={{ minWidth: 150 }}
          >
            {Object.values(MOVEMENT.TYPE).map((eachType, index) => (
              <MenuItem key={index} value={eachType}>
                {eachType}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack
          display="flex"
          direction="row"
          spacing={2}
          justifyContent="flex-end"
        >
          <Button variant="outlined" color="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            variant="contained"
            color="primary"
            onClick={addBtnHandler}
            variant="solid"
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </MyModal>
  );
};

export default AddNewMovementModal;
