import React from "react";
import { Box, Divider, Typography, Stack, Button } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";

/* Own Modules */
import _DashboardBlankPage from "./_DashboardBlankPage";
import { checkout } from "../../store/cart-slice";
import CartItem from "../../components/Cart/CartItem";
import MyCard from "../../components/Cards/MyCard";

function ShoppingCartPage() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const handleCheckout = () => {
    dispatch(checkout(cart.cartItems));
  };

  /* ------- [Returns] --------- */
  return (
    <_DashboardBlankPage title="Shopping Cart">
      <Stack
        spacing={2}
        sx={{
          display: "flex",
          maxWidth: "800px",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <MyCard>
          <Box>
            <Typography level="title-md">Your Shopping Cart</Typography>
          </Box>
          <Divider />
          {cart.cartItems.map((eachItem) => (
            <CartItem
              key={eachItem.programUID}
              imageSrc={eachItem.coverArt}
              title={eachItem.title}
              id={eachItem.programUID}
              pricing={eachItem.pricing}
            />
          ))}
          {cart.cartItems.length === 0 && (
            <Typography level="body-sm">Your cart is empty</Typography>
          )}
          {cart.cartItems.length > 0 && (
            <Stack direction={"row"}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                variant="outlined"
                onClick={handleCheckout}
                loading={cart.processingCheckout}
              >
                {`Checkout ${cart.totalPrice} €`}
              </Button>
            </Stack>
          )}
        </MyCard>
      </Stack>
    </_DashboardBlankPage>
  );
}

export default ShoppingCartPage;
