import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import WidgetCard from "../components/Cards/WidgetCard";
import OneRMRecordView from "../components/_1RMRecordView.js";

/* Authentication imports */
import AuthContext from "../store/auth-context";

const _1RMWidget = () => {
  const authCtx = useContext(AuthContext);
  const userData = useSelector((state) => state.user.userInfoData);

  const _1RMDATA_INIT = userData.Records_1RM ? userData.Records_1RM : [];
  const [_1RMData, set_1RMData] = useState(_1RMDATA_INIT);
  const [edit, setEdit] = useState(false);
  const isLoading = _1RMData.length > 0 ? false : true;

  useEffect(() => {
    if (userData.Records_1RM) {
      set_1RMData(userData.Records_1RM);
    }
  }, [userData.Records_1RM]);

  const getRecordValue = (id, record) => {
    const temp = JSON.parse(JSON.stringify(_1RMData));
    temp.map((mov) => {
      if (mov.id === id) {
        mov._1RM = record;
      }
      return mov;
    });

    set_1RMData(temp);
  };

  const onEditHandler = () => {
    setEdit(true);
  };

  const onSubmit1RmUpdate = () => {
    _1RMData.forEach((mov) => {
      authCtx.update1rmRecord(mov.id, mov._1RM);
      // TODO: do the batch update for 1RM
    });
    setEdit(false);
    //TODO: error handeling
  };

  return (
    <WidgetCard title="1RM Records">
      {isLoading && <CircularProgress color="primary" />}
      {!isLoading &&
        _1RMData.map((eachRecord) => (
          <Grid
            key={eachRecord.id}
            container
            item
            sm={12}
            sx={{ mt: 1, mb: 1 }}
          >
            <OneRMRecordView
              id={eachRecord.id}
              title={eachRecord.name}
              record={eachRecord._1RM}
              getValue={getRecordValue}
              unit={eachRecord.measureType}
              editable={edit}
            ></OneRMRecordView>
          </Grid>
        ))}
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Divider textAlign="right" sx={{ width: "100%" }}>
            Edit
          </Divider>
        </Grid>
        <Grid item xs={2} justifyItems="flex-end">
          <IconButton aria-label="edit" onClick={onEditHandler}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
      {edit && (
        <Grid container alignItems="center" justifyContent="center">
          <Button onClick={onSubmit1RmUpdate} variant="contained" fullWidth>
            Submit 1RM
          </Button>
        </Grid>
      )}
    </WidgetCard>
  );
};

export default _1RMWidget;
