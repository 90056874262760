import { Grid, Typography } from "@mui/joy";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { loadProgrammeWithUID } from "../store/firebaseActions";
import Hero from "../components/HeroPage/Hero";
import PageSection from "../components/HeroPage/PageSection";
import { useTheme } from "@mui/material/styles";
import FeaturingApp from "../components/HeroPage/FeaturingApp";
import { motion, useAnimation } from "framer-motion";
import ProductCard from "../components/ProductCard/ProductCard";

/* stylig */
const useStyles = makeStyles((theme) => ({
  hero: {
    position: "relative",
  },
  bodyContent: {
    paddingTop: 100,
    transform: "skewY(10deg)",
    paddingBottom: 50,
  },
  sectionSignature: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 60,
  },
  sectionApp: {
    display: "flex",
    flexDirection: "column",
    marginTop: 120,
  },
  colorChangeSection: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.primary.main,
    marginTop: 80,
  },
}));

const LemonadeCompeteUID =
  process.env.NODE_ENV === "development"
    ? "xmxId6bVa9I91UOE4QMJ"
    : "btsoOIOQjipQMuesqps1";

const LemonadeMastersTestUID =
  process.env.NODE_ENV === "development"
    ? "xmxId6bVa9I91UOE4QMJ"
    : "prod_QtX0tRjiLOr1Ja";

const LemonadeMastersUID =
  process.env.NODE_ENV === "development"
    ? "xmxId6bVa9I91UOE4QMJ"
    : "prod_QtYiuvSdzzVrLo";

const LemonadeLiteUID =
  process.env.NODE_ENV === "development"
    ? "xmxId6bVa9I91UOE4QMJ"
    : "QrUJjzeaSN6kp3O1QiVV";

const LemonadeTestUID =
  process.env.NODE_ENV === "development"
    ? "xmxId6bVa9I91UOE4QMJ"
    : "TO0ocK3aEskPFBgtogEm";

const LemonadeCompPrepUID =
  process.env.NODE_ENV === "development"
    ? "xmxId6bVa9I91UOE4QMJ"
    : "kynX1XYOCvPupQsxhb6F";

const LemonadeCompPrepTypography = (
  <>
    <Typography level="h4">COMP PREP </Typography>
    <Typography textAlign={"justify"}>-</Typography>
    <Typography textAlign={"justify"}>
      This is a competition preparation week of training. It is planned as the
      week leading into competition.
    </Typography>
  </>
);

const LemonadeTestTypography = (
  <>
    <Typography level="h4">Lemonade TEST WEEK </Typography>
    <Typography> * One full test week of COMPETE program </Typography>
    <Typography> * Effort Level: 5/5 </Typography>
    <Typography> * 5 workout days/week (90-150 min)</Typography>
    <Typography>
      * Strength & WL progressions, gymnastics skill & capacity, and
      sport-specific training
    </Typography>
    <Typography> * LT App for following & logging workout data</Typography>
  </>
);

const LemonadeCompeteTypography = (
  <>
    <Typography level="title-lg">Lemonade COMPETE </Typography>
    <Typography> * Effort Level: 5/5 </Typography>
    <Typography> * 5 workout days/week (90-150 min)</Typography>
    <Typography>
      * Strength & WL progressions, gymnastics skill & capacity, and
      sport-specific training
    </Typography>
    <Typography>* LT App for following & logging workout data</Typography>
    <Typography>
      * Group chat for coach & athletes, LT Training days{" "}
    </Typography>
    <Typography>* 40 e/month, no cancellation period</Typography>
    <Typography>-</Typography>
    <Typography textAlign={"justify"}>
      {`This is our signature program designed to improve all areas of fitness. We periodise our training into 6-12 weeks cycles. The main goal is to peak for spring/summer competitions, this might be the Open, local or international competitions. Each phase of training will have certain goals in mind. `}
    </Typography>
  </>
);

const LemonadeLiteTypography = (
  <>
    <Typography level="title-lg">Lemonade LITE</Typography>
    <Typography> * Effort Level: 3/5 </Typography>
    <Typography> * 3 workout days/week (60-90 min)</Typography>
    <Typography>
      * Strength & WL progressions, gymnastics skill & capacity, and
      sport-specific training
    </Typography>
    <Typography>* LT App for following & logging workout data</Typography>
    <Typography>* Group chat for coach & athletes, LT Training days</Typography>
    <Typography>* ONLY 18 e/month, no cancellation period</Typography>
    <Typography>-</Typography>
    <Typography textAlign={"justify"}>
      {`This is a perfect program for those of you that are short of time or just want to train three times per week. Each workout includes warm up and the main session (strength and/or sweaty workout). `}
    </Typography>
  </>
);

const LemonadeMastersTypography = (
  <>
    <Typography level="title-lg">Lemonade MASTERS</Typography>
    <Typography> * Effort Level: 4/5 </Typography>
    <Typography> * 5 workout days/week (60-90 min)</Typography>
    <Typography>
      * Strength & WL progressions, gymnastics skill & capacity, sport-specific
      & recovery session
    </Typography>
    <Typography>* Age group specific scaling</Typography>
    <Typography>* LT App for following & logging workout data</Typography>
    <Typography>* Group chat for coach & athletes, LT Training days</Typography>
    <Typography>* 40 e/month, no cancellation period</Typography>
    <Typography>-</Typography>
    <Typography textAlign={"justify"}>
      {`Online training Program specifically crafted for Masters athletes (age groups 35-55+). Strength & power, conditioning, skill training and mobility & recovery sessions, all included. Planned by Masters athlete Charlie Hodge.`}
    </Typography>
  </>
);

const LemonadeMastersTestTypography = (
  <>
    <Typography level="title-lg">Lemonade MASTERS TEST WEEK</Typography>
    <Typography> * Effort Level: 4/5 </Typography>
    <Typography> * 5 workout days/week (60-90 min)</Typography>
    <Typography>
      * Strength & WL progressions, gymnastics skill & capacity, sport-specific
      & recovery session
    </Typography>
    <Typography>* Age group specific scaling</Typography>
    <Typography>* LT App for following & logging workout data</Typography>
    <Typography>-</Typography>
    <Typography textAlign={"justify"}>
      {`Online training Program specifically crafted for Masters athletes (age groups 35-55+). Strength & power, conditioning, skill training and mobility & recovery sessions, all included. Planned by Masters athlete Charlie Hodge.`}
    </Typography>
  </>
);

const LemonadePlansSections = (props) => {
  const [programming, setProgramming] = useState(null);

  useEffect(() => {
    const loadPrograming = async () => {
      if (props.programUID) {
        loadProgrammeWithUID(props.programUID)
          .then((newData) => {
            setProgramming(newData);
          })
          .catch((error) => {
            console.log("LemonadeTestSection]->", error);
          });
      }
    };

    // executing use effect
    return loadPrograming();
  }, [props.programUID]);

  return (
    <PageSection>
      <Grid container sx={{ mt: 8 }}>
        <Grid lg={1} md={0} />
        <Grid lg={4} md={12} sx={{ mt: 1, mb: 1 }}>
          {programming && (
            <>
              <ProductCard
                disableOnClick
                UID={programming?.id}
                data={programming.data}
              />
            </>
          )}
          {!programming && <Typography level="h4">Coming Soon...</Typography>}
        </Grid>
        <Grid lg={5} md={12}>
          {props.description}
        </Grid>
        <Grid lg={1} md={0} />
      </Grid>
    </PageSection>
  );
};

/*
 * ======================================================
 * Signature Programmings
 * ======================================================
 */
const SignatureProgrammings = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.bodyContent}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid lg={1} md={0} />
        <Grid xs={10}>
          <div className={classes.sectionSignature}>
            <Typography
              level="h2"
              sx={{
                alignSelf: "center",
              }}
            >
              Our Signature Programmings
            </Typography>
          </div>
          <LemonadePlansSections
            programUID={LemonadeCompeteUID}
            description={LemonadeCompeteTypography}
          />
          <LemonadePlansSections
            programUID={LemonadeLiteUID}
            description={LemonadeLiteTypography}
          />
          <LemonadePlansSections
            programUID={LemonadeMastersUID}
            description={LemonadeMastersTypography}
          />
          <LemonadePlansSections
            programUID={LemonadeCompPrepUID}
            description={LemonadeCompPrepTypography}
          />
          <LemonadePlansSections
            programUID={LemonadeTestUID}
            description={LemonadeTestTypography}
          />
          <LemonadePlansSections
            programUID={LemonadeMastersTestUID}
            description={LemonadeMastersTestTypography}
          />
        </Grid>
        <Grid lg={1} md={0} />
      </Grid>
    </div>
  );
};

/*
 * ======================================================
 * Home Page
 * ======================================================
 */
const HomePage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.hero}>
        <Hero />
      </div>
      <motion.div
        initial={{
          skewY: -10,
          background: "rgb(255,255,255)",
          marginTop: -450,
        }}
      >
        <SignatureProgrammings />
      </motion.div>
      <motion.div initial={{ marginTop: 100 }}>
        <FeaturingApp />
      </motion.div>
    </>
  );
};

export default HomePage;
