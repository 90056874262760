import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { FormControlLabel } from "@mui/material";
import { Switch, Box, Typography, Button } from "@mui/joy";
import SaveIcon from "@mui/icons-material/Save";
import { uploadUserPrivacySettings } from "../../store/user-slice";

const ProfilePrivacySetting = (props) => {
  const dispatch = useDispatch();
  const [privacySettings, setPrivacySettings] = useState({
    allowMarketing: props.privacySettings?.allowMarketing
      ? props.privacySettings.allowMarketing
      : false,
  });
  const [privacySettingChanged, setPrivacySettingChanged] = useState(false);

  const [checked, setChecked] = React.useState(false);

  /* --------[handlers] ------------- */
  const handlePrivacySetting = (event) => {
    const { ariaLabel, checked } = event.target;

    setPrivacySettingChanged(true);
    setPrivacySettings((prev) => ({
      ...prev,
      [ariaLabel]: checked,
    }));
  };

  const onSavePrivacySetting = () => {
    setPrivacySettingChanged(false);

    dispatch(uploadUserPrivacySettings(props.userUID, privacySettings));
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          alignItems: "center",
        }}
      >
        <Typography variant="body1">Receive marketing emails?</Typography>
        <Box sx={{ flex: "1 1 auto" }} />
        <Typography
          component={"label"}
          endDecorator={
            <Switch
              variant="outlined"
              checked={privacySettings.allowMarketing}
              onChange={handlePrivacySetting}
              slotProps={{ input: { "aria-label": "allowMarketing" } }}
            />
          }
        >
          {privacySettings.allowMarketing ? "Yes" : "Don't allow"}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          endDecorator={<SaveIcon />}
          variant="outlined"
          disabled={!privacySettingChanged}
          onClick={onSavePrivacySetting}
        >
          Save Privacy
        </Button>
      </Box>
    </Fragment>
  );
};

export default ProfilePrivacySetting;
