import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./store/auth-context";
import { materialTheme, LemonadeTheme } from "./theme";
import appReduxStore from "./store/appReduxStore";

import {
  useColorScheme as useMaterialColorScheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID,
} from "@mui/material/styles";

import {
  CssVarsProvider as JoyCssVarsProvider,
  useColorScheme as useJoyColorScheme,
} from "@mui/joy/styles";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={appReduxStore}>
      <AuthContextProvider>
        <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
          <JoyCssVarsProvider theme={LemonadeTheme}>
            <App />
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
